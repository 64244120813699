@import url("animations.scss");
@import url("swiper/swiper.min.css");

$theme-color: #6f42c1;

.HW_badge_cont {
	position: absolute !important;
	top: 0;
	left: 0;
	width: 100% !important;
	height: 100% !important;
}

/* Form Styles */
:root {
	--light-periwinkle: #dfe3e9;
	--bookee-whiteish: #f7f7f7;
	--bookee-primary: #7850fa;
	--bookee-gray: #546a7b;
	--bookee-faded-gray: #7e95a3;
	--bookee-light-gray: #f0f1f5;
	--bookee-dark-gray: #f5f6fa;
	--bookee-red: #e0463a;
	--bookee-light-blue: #00ace0;
	--bookee-yellow: #eb9c00;
	--bookee-black: #0d1f2d;
	--bookee-green-bg: #e6fae6;
	--bookee-green: #40bf26;
	font-family: 'Lato', sans-serif !important;

}

.theme-color-font {
	color: $theme-color;
}

.message-blue {
	padding: 8px 8px 8px 12px;
	border-radius: 8px;
	background-color: #f2fbfd;
	display: flex;
	margin-bottom: 24px;

	&.no-bg {
		padding: 8px 8px 8px 24px;
		background-color: #fff;
		margin-bottom: 0px;
	}

	span {
		font-size: 13px;
		line-height: 1.38;
		color: #0d1f2d;
		margin-left: 6px;
	}

}

.message-red {
	padding: 8px 8px 8px 12px;
	border-radius: 8px;
	background-color: rgba(255, 0, 0, 0.1);
	display: flex;
	margin-bottom: 24px;

	&.sm {
		padding: 6px;

		span {
			font-size: 12px;
		}
	}

	&.no-bg {
		padding: 8px 8px 8px 24px;
		background-color: #fff;
		margin-bottom: 0px;
	}

	span {
		font-size: 13px;
		line-height: 1.38;
		color: red;
		margin-left: 6px;
	}

}

.cursor-pointer {
	cursor: pointer;
}

.pointer-events-none {
	pointer-events: none;
}

.error-msg-banner {
	display: flex;
	gap: 12px;
	align-items: center;
	border-radius: 6px;
	border: 1px solid #FDA29B;
	background: #FFFBFA;
	color:  #B42318;
	font-size: 14px;
	font-weight: 400;
	padding: 8px 16px;
}

.info-msg-banner {
	display: flex;
	gap: 12px;
	align-items: center;
	border-radius: 6px;
	background: linear-gradient(0deg, rgba(252, 240, 205, 0.7), rgba(252, 240, 205, 0.7));
	font-size: 14px;
	font-weight: 400;
	padding: 8px 16px;
}

.inline-radio-option{
	display: flex;
	align-items: center;
	gap: 32px;
	.mat-radio-label{
		display: flex;
		align-items: center;
		.mat-radio-label-content {
			font-family: Lato;
			font-size: 14px;
			color: #0d1f2d;
			font-weight: normal;
			vertical-align: super;
		}
	}
	.mat-radio-disabled {
		.mat-radio-label-content {
			color: rgba(0, 0, 0, 0.38);
		}
	}
}

.col-radio-option{
	display: flex;
	flex-direction: column;
	gap: 12px;
	.mat-radio-label{
		display: flex !important;
		align-items: center;
		flex-direction: row !important;
		margin: 0 !important;
		.mat-radio-label-content {
			font-family: Lato;
			font-size: 14px;
			color: #0d1f2d;
			font-weight: normal;
			vertical-align: super;
			display: flex !important;
			align-items: center !important;
		}
	}
	.mat-radio-disabled {
		.mat-radio-label-content {
			color: rgba(0, 0, 0, 0.38);
		}
	}

	.custom-radio-option {
		.mat-radio-label{
			display: flex !important;
			align-items: flex-start !important;
			flex-direction: row !important;
			margin: 0 !important;
			.mat-radio-label-content {
				font-family: Lato;
				font-size: 14px;
				color: #0d1f2d;
				font-weight: normal;
				vertical-align: super;
				display: flex !important;
				flex-direction: column !important;
				align-items: flex-start !important;
			}
		}
	}
}

.form-fitday {
	overflow: visible;

	.form-field {
		margin-bottom: 24px;

		&#form-field-filter {
			margin-bottom: 0px;
		}

		.form-toggle-label {
			font-size: 14px;
			font-weight: bold;
			color: #546a7b;
			margin-left: 10px;
		}

		.form-toggle-subtext {
			font-size: 12px;
			margin-top: 4px;
			font-weight: normal;
			color: #546a7b;
			margin-left: 10px;
		}

		.switch-text {
			display: flex;
			flex-direction: column;
		}

		label {
			display: block;
			font-family: Lato;
			font-size: 13px;
			font-weight: bold;
			letter-spacing: 0.2px;
			color: #546a7b;
			margin-bottom: 8px;

			&.required {
				&:after {
					content: '*';
					font-size: 16px;
					margin-left: 2px;
				}
			}
		}

		input[type="text"],
		input[type="number"],
		input[type="password"],
		textarea {
			width: 100%;
			padding: 9px 11px;
			font-family: Lato;
			font-size: 14px;
			color: #0d1f2d;
			background-color: #ffffff;
			border: solid 1px #dfe3e9;
			border-radius: 6px;

			&.max-width-unset {
				max-width: unset;
			}

			&.max-width-120 {
				max-width: 120px;
			}

			&.max-width-200 {
				max-width: 200px;
			}

			&.max-width-480 {
				max-width: 480px;
			}

			&.border-bottom {
				border-bottom: 1px solid #dfe3e9;
			}

			&:focus {
				border: solid 1px #7850FA;
				box-shadow: none;
			}

			&:disabled {
				background-color: #eaebef;
				color: #546a7b;
			}

			&::placeholder {
				color: #7e95a3;
			}

			&.invalid {
				border-color: red !important;
			}

			&.small-number {
				width: 64px;
			}
		}

		select {
			font-family: Lato;
			font-size: 14px;
			border-radius: 0px;

			&.invalid {
				border-color: red;
			}
		}

		.text-content {
			font-family: Lato;
			font-size: 14px;
			color: #0d1f2d;

			&.text-purple {
				color: #7850FA;
				font-weight: 600;
				cursor: pointer;
			}
		}


		.one-of-content {
			width: 100%;
			display: flex;

			&.disabled {
				opacity: 0.4;
				pointer-events: none;
			}

			.one-of-radio {
				width: 164px;
				height: 92px;
				border: solid 1px #dfe3e9;
				background-color: #ffffff;
				transition: all 0.3s cubic-bezier(.25, .8, .25, 1);

				&#one-of-radio-pricing {
					cursor: pointer;
					height: 104px;
					width: 184px;
					border-radius: 6px;
					margin-right: 20px;
					text-align: center;

					.one-of-x-content {
						padding: 16px 12px 16px 16px;
						display: flex;
						flex-direction: column;

						img {
							margin-bottom: 8px;
						}

						.one-of-type {
							font-size: 13px;
							text-align: center;
							color: #0d1f2d;
							margin-bottom: 3px;
						}

						.one-of-desc {
							font-size: 11px;
							color: #7e95a3;
						}
					}
				}

				&.one-of-radio-sm {
					cursor: pointer;
					width: 96px;
					height: auto;
					border-radius: 12px;
					text-align: center;

					&:nth-child(2) {
						margin-right: 8px;
						margin-left: 8px;
					}

					.one-of-x-content {
						padding: 12px;
						display: flex;
						flex-direction: column;
						align-items: center;

						img {
							margin-bottom: 8px;
							width: 28px;
							height: 28px;
						}

						.one-of-type {
							font-size: 12px;
							text-align: center;
							font-weight: bold;
							color: #0d1f2d;
						}

						.one-of-desc {
							font-size: 11px;
							color: #7e95a3;
						}
					}

					&.active {
						.one-of-x-content {
							.one-of-type {
								color: var(--bookee-primary);
							}
						}
					}
				}

				&#one-of-radio-lg {
					cursor: pointer;
					width: 256px;
					height: auto;
					border-radius: 6px;
					margin-right: 20px;

					.one-of-x-content {
						padding: 16px 12px 16px 16px;
						display: flex;
						flex-direction: column;

						.one-of-type {
							font-size: 15px;
							font-weight: bold;
							color: #0d1f2d;
							margin-bottom: 6px;
						}

						.one-of-desc {
							font-size: 12px;
							color: #546a7b;
						}
					}
				}


				svg {
					display: flex;
					margin: auto;
					margin-top: 16px;
					margin-bottom: 8px;
				}

				label {
					display: flex;
					align-items: center;
					justify-content: center;
					font-family: Lato;
					font-size: 12px;
					font-weight: bold;
					color: #546a7b;
				}

				&:hover {
					border: solid 1px #7850FA;
				}

				&.active {
					border: solid 1px #7850FA;
					color: var(--bookee-primary);
					background-color: rgba(122, 81, 204, 0.08);

					svg {
						g {
							fill: #7850FA;
						}
					}

					label {
						color: #7850FA;
					}
				}
			}

			input[type="radio"] {
				opacity: 0;
				width: 174px;
				height: 92px;
				position: absolute;
				cursor: pointer;
			}
		}

	}
}


.form-error-msg {
	font-family: Lato;
	font-size: 12px;
	color: red;
	margin: 4px 0 0 4px;
}


.form-info-msg {
	color: #546A7B;
	font-size: 13px;
	margin: 4px 0 0 4px;
}


.p-component {
	font-family: Lato !important;
}

.p-dropdown-item {
	padding: 7px 16px !important;

}

.p-menu {
	right: 0;
	left: auto !important;
	top: 50px !important;

	.menuItem {
		display: flex;
		align-items: center;
		color: #101828;
		font-size: 14px;
		font-weight: 400;
		line-height: 16px;

		&.danger {
			color: #C75048;
		}
	}
}

body .p-dropdown-panel {


	border-radius: 4px;
	border: 1px solid #eaebef;
	box-shadow: 0 6px 12px 0px rgba(33, 33, 33, 0.08), 0 0px 16px 0px rgba(33, 33, 33, 0.05);

	.p-dropdown-items-wrapper {
		max-height: 320px !important;
		&::-webkit-scrollbar {
			width: 0;
			height: 0;
		}

		.p-dropdown-item-group {
			background: none;
			padding: 10px 12px 4px;
		}
	}

	.p-dropdown-items {
		padding: 6px 0;

		::-webkit-scrollbar {
			width: 0;
			height: 0;
		}

		.p-dropdown-item {
			color: #0d1f2d !important;
			padding: 7px 24px !important;
			font-size: 14px;

			&:hover {
				background-color: #f2f3f7 !important;
			}

			&.p-highlight {
				background-color: transparent !important;
				color: #7850FA !important;
			}

			&.p-disabled {
				cursor: not-allowed !important;
			}
		}
	}
}

/* For Dropdowns attached to body (placed inside modals) */
body .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group:not(.p-highlight):hover,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group:not(.p-disabled):hover {
	background-color: none !important;
}

body .p-dropdown-items {
	padding: 6px 0 !important;

	::-webkit-scrollbar {
		width: 0;
		height: 0;
	}

	.p-dropdown-item {
		color: #0d1f2d !important;
		padding: 7px 16px !important;
		font-size: 14px;

		&:hover {
			background-color: #f2f3f7 !important;
		}

		&.p-highlight {
			background: none;
			color: #7850FA !important;
		}

		&.p-disabled {
			cursor: not-allowed !important;
		}
	}
}

body .p-multiselect-panel {
	border-radius: 4px;
	border: 1px solid #eaebef;
	box-shadow: 0 6px 12px 0px rgba(33, 33, 33, 0.08), 0 0px 16px 0px rgba(33, 33, 33, 0.05);

	.p-multiselect-items-wrapper {
		padding: 6px 0;
		max-height: 320px;
	}

	.p-multiselect-item {
		display: flex;
		align-items: center;
		padding: 7px 14px;
		font-size: 14px;

		&:focus {
			box-shadow: none !important;
		}

		&:not(.p-disabled):hover {
			background-color: rgba(120, 80, 250, 0.3) !important;
		}

		&.p-highlight {
			background-color: #fff !important;
			color: var(--bookee-gray) !important;

			label {
				color: #546a7b;
			}
		}

		.p-checkbox {
			.p-checkbox-box.p-highlight {
				border-color: var(--bookee-primary);
				background: var(--bookee-primary);
			}
		}

		/*	.p-chkbox-box {
			border-radius: 4px;
			height: 18px;
			width: 18px;
			margin: 0;
			font-size: 14px;
			.p-chkbox-icon {
				font-size: 14px;
			}
			&:not(.p-disabled):hover {
				border-color: var(--bookee-primary);
			}
			&.p-state-active {
				background: #7850FA;
				border: none;
				&:not(.p-disabled):hover {
					background: rgba($color: #7850FA, $alpha: 0.7);
					border-color: #7850FA;
				}
			}
		} */

		label {
			margin: 0;
			color: #7e95a3;
			font-size: 14px;
			font-weight: 600;
		}
	}
}

.personalization-dropdown {
	.p-dropdown-label {
		padding: 6px 8px !important;

		&.p-placeholder {
			color: #0d1f2d !important;
		}
	}
}

.reuse-table {
	margin-bottom: 15px;

	.reuse-table-head {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		.p-input-icon-left {
			padding: 12px 10px;
			height: 42px;
			width: 280px;
			background: #FFFFFF;
			border: 1px solid #CED4DA;
			border-radius: 6px;

			input {
				font-size: 14px;
				line-height: 21px;
				color: #6C757D;
			}

			&:hover {
				border: 1px solid var(--bookee-primary);
			}
		}

	}

	.p-datatable {
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		border: 1px solid #DEE2E6;
		border-bottom: none;

		.p-datatable-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			background: #F8F9FA;
			padding: 24px 16px;
			border-bottom: 1px solid #DEE2E6;
			border-top: none;
			border-left: none;
			border-right: none;

		}

		.p-datatable-thead>tr>th {
			border: none;
			background: #F8F9FA;
			padding: 16px;
			color: #343A40;
			font-size: 14px;
			font-weight: bold;
		}

		.p-datatable-tbody>tr>td {
			padding: 13px 16px;
			background: #FFFFFF;
			border: none;
			border-bottom: 1px solid #DEE2E6;
			font-size: 14px;
			color: #495057;
			cursor: pointer;
		}

		.p-sortable-column.p-highlight {
			background: #F6F2FF;
			color: #7A45E5;
		}

		.p-sortable-column.p-highlight:hover {
			background: #F6F2FF;
			color: #7A45E5;
		}

		.p-sortable-column.p-highlight .p-sortable-column-icon {
			color: #7A45E5;
		}

		.p-sortable-column.p-highlight:hover .p-sortable-column-icon {
			color: #7A45E5;
		}

		.p-sortable-column:focus {
			box-shadow: none;
		}
	}

	.mat-checkbox-frame {
		border: 2px solid #CED4DA;
		border-radius: 6px;
	}

	.mat-checkbox-inner-container {
		width: 21px !important;
		height: 21px !important;
	}

	.mat-checkbox-layout {
		margin: 0;
	}
}

.table-paginator {
	.p-paginator {
		background: #FFFFFF;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		border: 1px solid #DEE2E6;
		border-top: none;

		padding: 24px 16px;

		.p-paginator-pages .p-paginator-page.p-highlight {
			font-size: 14px;
			background: #7850FA;
			border-color: #7850FA;
			border-radius: 50%;
			color: #FFFFFF;
			margin: 0 4px;
		}

		.p-paginator-pages .p-paginator-page {
			font-family: 14px;
			color: #6C757D;

			&:hover {
				border-radius: 50%;
				background-color: #d8dbe5;
			}
		}

		.p-dropdown {
			border: 1px solid #CED4DA;
			border-radius: 6px;
		}
	}
}

.item-tabs {
	.p-tabview .p-tabview-panels {
		border: none;
		padding: 0;
	}

    .p-dropdown{
        border-radius: 6px;
        width: 100%;
    }

	.p-tabview .p-tabview-nav-content {
		border-bottom: 1px solid #DEE2E6;
	}

	.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
		background-color: transparent;
		border: none;
		padding: 18px;
		border-bottom: 3px solid var(--bookee-primary);

		&:focus {
			outline: none !important;
		}
	}

	.p-tabview .p-tabview-nav li .p-tabview-nav-link {
		background-color: transparent;
		border: none;
		padding: 18px;

		&:focus {
			outline: none !important;
			box-shadow: unset;
		}
	}


	.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
		color: var(--bookee-primary);
		text-decoration: none;
		outline: none !important;

		&:focus {
			outline: none !important;
			box-shadow: unset;
		}
	}

	.p-tabview .p-tabview-nav .p-tabview-nav-link {
		color: var(--bookee-gray);
		text-decoration: none;
		outline: none !important;

		&:focus {
			outline: none !important;
			box-shadow: unset;
		}
	}

	&.background-gray {
		.p-tabview .p-tabview-panels {
			background: #f7f8fa;
		}
	}
}

.select-dropdown {

	&.country-code {
		.p-dropdown {
			min-width: 5em;
			margin-right: 8px;
		}
	}

	.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group:not(.p-highlight):hover,
	.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group:not(.p-disabled):hover {
		background-color: none !important;
	}

	.p-dropdown {
		border: 1px solid #dfe3e9;
		border-radius: 6px;

		&:not(.p-disabled):hover {
			border-color: #7850FA !important;
		}

		.p-dropdown-label {
			margin: 0;
			padding: 9px;
			font-family: Lato;
			font-size: 14px;
			color: #0d1f2d;
			font-weight: 400;
			border-radius: 6px;

			&.p-placeholder {
				color: #7e95a3;
			}
		}

		.p-dropdown-trigger {
			border-radius: 6px;
		}

		.pi-caret-down:before {
			content: "\e906";
			font-size: 15px;
			color: #7f97a6;
		}

		.p-dropdown-items-wrapper {
			max-height: 320px;
		}

		.p-dropdown-panel {


			border-radius: 4px;
			border: 1px solid #eaebef;
			box-shadow: 0 6px 12px 0px rgba(33, 33, 33, 0.08), 0 0px 16px 0px rgba(33, 33, 33, 0.05);

			.p-dropdown-items {
				padding: 6px 0;

				.p-dropdown-item {
					color: #0d1f2d !important;
					padding: 7px 16px !important;

					&:hover {
						background-color: #f2f3f7 !important;
					}

					&.p-highlight {
						background: none;
						color: #7850FA !important;
					}

					&.p-disabled {
						cursor: not-allowed !important;
					}
				}
			}
		}
	}

	.p-dropdown:not(.p-disabled).p-focus {
		border-color: #7850FA !important;
		box-shadow: none;
	}

	.p-dropdown-trigger {
		transform: scale(0.8);
	}

	&.disabled {
		.p-dropdown {
			opacity: 1;
			display: inline-flex;

			.p-dropdown-label {
				background-color: #eaebef;
				color: #546a7b;
			}

			.p-dropdown-trigger {
				display: none;
			}
		}
	}

	&.invalid {
		.p-dropdown {
			border-color: red;
		}
	}
}

.rule-engine-group {
	.p-multiselect-item-group {
		padding: 8px 14px !important;
	}
}

.multiselect-dropdown {
	.p-multiselect {
		width: 320px;
		border: 1px solid #dfe3e9;
		border-radius: 6px;

		&:not(.p-disabled).p-focus {
			outline: none;
			box-shadow: none;
			border-color: var(--bookee-primary);
		}

		.p-multiselect-trigger {
			border-top-right-radius: 6px;
			border-bottom-right-radius: 6px;
		}

		&:not(.p-disabled):hover {
			border-color: #7850FA !important;
		}

		&:focus {
			box-shadow: none;
		}

		.p-multiselect-label-container {
			.p-multiselect-label {
				margin: 0;
				padding: 9px;
				font-size: 14px;
				color: #0d1f2d;
				font-weight: 400;
			}
		}

		.pi-caret-down:before {
			content: "\e906";
			font-size: 15px;
			color: #7f97a6;
		}

		.p-multiselect-panel {
			border-radius: 4px;
			border: 1px solid #eaebef;
			box-shadow: 0 6px 12px 0px rgba(33, 33, 33, 0.08), 0 0px 16px 0px rgba(33, 33, 33, 0.05);

			.p-multiselect-header {
				.p-checkbox {
					.p-checkbox-box.p-highlight {
						border-color: var(--bookee-primary);
						background: var(--bookee-primary);
					}
				}
				.p-multiselect-close:hover {
					.p-multiselect-close-icon {
						color: var(--bookee-primary);
					}
				}
			}

			.p-multiselect-items-wrapper {
				padding: 6px 0;
				max-height: 320px;
			}

			.p-multiselect-item {
				display: flex;
				align-items: center;
				padding: 7px 14px;
				font-size: 14px;

				&:hover {
					background-color: #F2F3F7 !important;
				}

				&.p-highlight {
					color: var(--bookee-gray) !important;
					background-color: #fff !important;

					label {
						color: #546a7b;
					}
				}

				label {
					margin: 0;
					color: #7e95a3;
					font-size: 14px;
					font-weight: 600;
				}
			}
		}
	}
}

.query-attribute-option-container {
	.multiselect-dropdown {
		.p-multiselect {
			width: 225px !important;
		}
	}
}

.forms-container {
	.multiselect-dropdown {
		.p-multiselect {
			width: 100% !important;
			border: 1px solid #eaebef !important;
			border-radius: 6px !important;
			outline: none;

			&:hover {
				border-color: #7e95a3 !important;
			}

			.p-multiselect-trigger {
				border-radius: 6px;
			}

		}
	}

	.radio-group {
		.mat-radio-label {
			margin: 0 !important;
			padding: 10px 12px 6px !important;

		}
	}

	.select-dropdown .p-dropdown {
		width: 100% !important;
		border: 1px solid #eaebef !important;
		border-radius: 6px !important;


		&>* {
			outline: none !important;
		}

		&:hover {
			border-color: #7e95a3 !important;
		}


		.p-dropdown-trigger {
			border-radius: 6px;
		}

		.p-dropdown-label {
			border-radius: 6px;
		}
	}

	.mat-checkbox {


		.mat-checkbox-layout {
			width: 100% !important;
			border: 1px solid #eaebef;
			border-radius: 6px !important;
			overflow: hidden;
			display: flex;
			align-items: center;
			height: 59px;

			&:hover {
				border-color: #7e95a3;
			}

			.mat-checkbox-frame {
				border-color: #b9bdc4;
			}

			.mat-checkbox-inner-container {
				margin: 12px;
			}

			.service-detail {
				.service-name {
					font-size: 15px !important;
					margin: 0 !important;
					color: #0d1f2d !important;
					font-weight: normal;
				}

				.service-description {
					font-size: 13px !important;
					margin: 0 !important;
					margin-top: 2px !important;
					color: #546a7b !important;
					font-weight: normal;
				}
			}
		}
	}


	.mat-checkbox-checked {

		.mat-checkbox-layout {

			border: solid 1px #7850fa !important;
			background-color: #f4f1ff;
		}

	}
}

.select-group-custom {
	.select-dropdown {
		.p-dropdown {
			width: 240px !important;

			.p-dropdown-label {
				display: flex;
				align-items: center;
				padding: 9px 16px !important;

				.mode-image {
					width: 32px;
					height: 18px;
					margin-right: 8px;
					margin-bottom: 1px;
				}
			}

			.p-dropdown-item-group {
				padding: 7px 16px 7px 10px;
				color: #0d1f2d;
				pointer-events: none;

				&:not(:first-child) {
					margin: 8px 14px;
					border-bottom: 1px solid #dfe3e9;
				}
			}

			.p-dropdown-item {
				padding: 11px 16px !important;

				.p-helper-clearfix {
					display: flex;
					align-items: center;

					.mode-image {
						width: 32px;
						height: 18px;
						margin-right: 8px;
					}

					.mode-label {
						display: inline-block;
					}
				}
			}
		}
	}
}


.toggle-pill {
	display: inline-block;
	margin-right: 8px;
	position: relative;

	input[type="radio"] {
		position: absolute;
		left: -9999px;

		+label {
			width: 140px;
			text-align: center;
			font-size: 14px;
			font-weight: bold;
			background-color: #fff;
			padding: 11px;
			color: #546a7b;
			border: 1px solid #e6e6e6;
			margin-bottom: 0;
			cursor: pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			border-radius: 8px;
		}

		&:not(:checked) {
			+label {
				&.disabled {
					pointer-events: none;
					border: solid 1px #dfe3e9;
					color: #546a7b;
					opacity: 0.5;
					font-weight: 600;
				}
			}
		}

		&:checked {
			+label {
				color: #7850FA;
				border: solid 2px #7850FA;
				border-radius: 8px;
			}
		}
	}

	&.merge-toggle {
		display: flex;
		flex: 1;
		height: auto;

		input[type="radio"] {
			position: absolute;
			opacity: 0;
			height: 0;
			width: 0;

			+label {
				width: 215px;
				font-size: 14px;
				display: flex;
				justify-content: flex-start;
				flex-direction: column;
				text-align: left;
				background-color: #fff;
				padding: 11px;
				color: #546a7b;
				border: 1px solid #e6e6e6;
				border-radius: 6px;
				margin-bottom: 0;
				cursor: pointer;
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;

				.title {
					font-weight: bold;
					color: black;
					margin: 4px 0 4px 0;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.desc {
					font-weight: normal;
					color: black;
				}
			}

			&:not(:checked) {
				+label {
					&.disabled {
						pointer-events: none;
						border: solid 1px #dfe3e9;
						color: #546a7b;
						opacity: 0.5;
						font-weight: 600;
					}

					.radio-button-indicator {
						position: relative;
						height: 18px;
						width: 18px;
						border: 2px solid #7e95a3;
						border-radius: 50%;

						&::before {
							content: " ";
							border-radius: 50%;
							position: absolute;
							top: -2px;
							left: -2px;
							width: 18px;
							height: 18px;
							margin: auto;
							transform: scale(0);
						}
					}
				}
			}

			&:checked {
				+label {
					color: #7850FA;
					border: solid 1px #7850fa;
					background-color: #f8f6ff;

					.radio-button-indicator {
						position: relative;
						height: 18px;
						width: 18px;
						border: 2px solid #7850FA;
						border-radius: 50%;
						transition: transform ease 280ms, background-color ease 280ms;

						&::before {
							content: " ";
							border-radius: 50%;
							position: absolute;
							background-color: #7850FA;
							transition: transform ease 280ms, background-color ease 280ms;
							top: -2px;
							left: -2px;
							width: 18px;
							height: 18px;
							margin: auto;
							transform: scale(0.5);
						}
					}
				}
			}
		}

	}
}


.toggle-pill-check {
	display: inline-block;
	margin-right: 10px;

	input[type="checkbox"] {
		display: none;

		+label {
			width: 56px;
			text-align: center;
			font-size: 14px;
			font-weight: bold;
			background-color: #fff;
			padding: 11px;
			color: #7e95a3;
			border: solid 1px #dfe3e9;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;

			&.label-classes {
				width: 156px;
				border-radius: 8px;
				padding-left: 20px;
			}

			&.label-appointments {
				width: 156px;
				border-radius: 8px;
				padding-left: 28px;
			}

			&:hover {
				cursor: pointer;
				box-shadow: 0 2px 3px -1px rgba(33, 33, 33, 0.1), 0 1px 4px 0 rgba(33, 33, 33, 0.1);
			}
		}



		&:checked {
			+label {
				color: #7850FA;
				border: solid 1px #7850FA;

				&.label-classes {
					background-color: rgba(119, 51, 255, 0.05);
					border: solid 1px #73f;
				}

				&.label-appointments {
					background-color: rgba(119, 51, 255, 0.05);
					border: solid 1px #73f;
				}

				&:hover {
					box-shadow: 0 2px 3px -1px rgba(122, 81, 204, 0.2), 0 1px 4px 0 rgba(122, 81, 204, 0.2);
				}

				&.disabled {
					pointer-events: none;
					opacity: 0.5;
				}
			}
		}



		&:not(:checked) {
			+label {
				&.disabled {
					pointer-events: none;
					border: solid 1px #dfe3e9;
					color: #546a7b;
					opacity: 0.5;
					font-weight: 600;
				}
			}
		}
	}

	&.plan-check {
		display: inline-block;
		margin-right: 10px;
		position: relative;


		position: relative;

		input[type="checkbox"] {
			-webkit-appearance: none;
			appearance: none;
			position: absolute;
			top: 12px;
			left: 10px;
			display: flex;
			cursor: pointer;
			align-items: center;
			height: 18px;
			width: 18px;
			;
			background-color: #fff;
			border-radius: 2px;

			&:after {
				content: ' ';
				background-image: url("../img/checkbox_unselected.svg");
				background-position: center center;
				background-size: 14px;
				background-repeat: no-repeat;
				display: inline-block;
				font-size: 14px;
				height: 100%;
				width: 100%;
			}

			&:checked {
				-webkit-appearance: none;
				appearance: none;
				position: absolute;
				top: 12px;
				left: 10px;
				display: flex;
				align-items: center;
				height: 18px;
				transform: scale(0.8);
				width: 18px;
				;
				background-color: #784FF9;
				padding: 0 3px;

				&:after {
					content: ' ';
					background-image: url("../img/select.svg");
					background-position: center center;
					background-size: 20px;
					background-repeat: no-repeat;
					display: inline-block;
					font-size: 14px;
					height: 100%;
					width: 100%;
					color: #99a1a7;
				}
			}

		}
	}
}


.switch {
	position: relative;
	display: inline-block !important;
	width: 31px !important;
	height: 18px;
	margin: 0;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;

	&:disabled+.slider {
		opacity: 0.5;
	}
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 12px !important;
	width: 12px !important;
	left: 3px !important;
	bottom: 3px !important;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

input:checked+.slider {
	background-color: #7850FA;
}



input:checked+.slider:before {
	-webkit-transform: translateX(13px);
	-ms-transform: translateX(13px);
	transform: translateX(13px);
}

.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}


select {
	font-size: 14px;
	line-height: 20px;
	padding: 9px;
	border: solid 1px #dfe3e9;
	background: url("/assets/img/arrow-down.svg");
	background-position: 90%;
	background-repeat: no-repeat;

	&:disabled {
		background-color: #eaebef;
		color: #546a7b;
	}
}


.back-link {
	width: 65px;
	cursor: pointer;
	font-family: Lato;
	font-size: 12px;
	font-weight: bold;
	color: #7e95a3;

	&:before {
		content: "";
		display: inline-block;
		width: 8px;
		height: 16px;
		background-image: url("/assets/img/arrow-simple-left.svg");
		margin-right: 5px;
		vertical-align: text-bottom;
	}
}

.default-link {
	color: #7850FA !important;
	font-size: 14px;
	cursor: pointer;
}


button {
	&:focus {
		outline: 0 !important;
	}
}

.btn-danger {
	color: #dc3545 !important;
	border: solid 1px #dc3545 !important;
	background-color: transparent !important;
}


.btn-sec {
	text-transform: unset !important;
	font-weight: bold !important;
	font-size: 14px !important;
	background-color: #e9eaf2 !important;
	color: #0d1f2d !important;
	border: none !important;
	padding: 9px 0 !important;
	height: 36px !important;
}

.btn-default {
	padding: 9px 16px;
	color: #fff;
	background-color: #7850FA;

	&:not(.btn-error) {
		border: 1px solid #7850FA;
	}

	font-size: 13px;
	font-weight: 700;
	letter-spacing: 0px;
	border-radius: 6px;
	min-width: 120px;
	line-height: 18px;
	text-transform: uppercase;

	&.btn-sm {
		font-size: 14px;
		line-height: 18px;
		padding: 9px 16px;
		min-width: auto;
		border-radius: 6px;
	}

	&:hover {
		background: #5A35D4;
	}

	&:disabled {
		background-color: #e9edf1;
		border: 1px solid #dfe3e9;
		color: #7e95a3;
		cursor: not-allowed;
		pointer-events: none;
	}

	&.btn-inline {
		padding: 10px;
		border-radius: 6px;
		font-size: 12px;
		line-height: 1.33;
		letter-spacing: 0.3px;
		min-width: auto;
	}
}

.btn-error {
	text-transform: unset !important;
	font-weight: bold !important;
	font-size: 14px !important;
	background-color: rgba(245, 100, 105, 0.12) !important;
	color: #c75048 !important;
	min-width: 0;
}

.btn-alternative {
	padding: 10px 16px;
	border: solid 1px #7850FA;
	color: #7850FA;
	background-color: transparent;
	font-size: 13px;
	font-weight: 700;
	line-height: 18px;
	border-radius: 4px;
	min-width: 120px;


	&:hover {
		color: #fff;
		background-color: #7850FA;
	}

	&:disabled {
		background-color: #e9edf1;
		border: 1px solid #dfe3e9;
		color: #7e95a3;
		cursor: not-allowed;
		pointer-events: none;
	}

	&.red {
		border: solid 1px #e65055;
		color: #e65055;

		&:hover {
			color: #fff;
			background-color: #e65055;
		}
	}
}

.btn-gray {
	color: #7e95a3;
	font-size: 13px;
	font-weight: 700;
	padding: 10px 16px;
	border: solid 1px #7e95a3;
	background-color: transparent;
	line-height: 18px;
	text-transform: uppercase;
	min-width: 120px;
	letter-spacing: 0px;
	border-radius: 4px;

	&:hover {
		color: #7850FA;
		border: 1px solid #7850FA;
		background-color: #7850FA1a !important;
	}

	&:disabled {
		background-color: #e9edf1;
		border: 1px solid #dfe3e9;
		color: #7e95a3;
		cursor: not-allowed;
		pointer-events: none;
	}
}

.btn-gray-fill {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	grid-gap: 5px;
	gap: 5px;
	padding: 9px 16px;
	border-radius: 6px;
	background-color: #eaebef;
	font-size: 14px;
	font-weight: bold;
	cursor: pointer;

	&:disabled {
		background-color: #e9edf1;
		border: 1px solid #dfe3e9;
		color: #7e95a3;
		cursor: not-allowed;
		pointer-events: none;
	}
}

.btn-del {
	background: #FFF0F0;
	border-radius: 6px;
	color: #C75048;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 16px;
	font-weight: 700;
	gap: 10px;
	cursor: pointer;

	&:disabled {
		background-color: #e9edf1;
		border: 1px solid #dfe3e9;
		color: #7e95a3;
		cursor: not-allowed;
		pointer-events: none;
	}
}

.radio-card-group {
	.mat-radio-label {
		position: relative;

		.mat-radio-container {
			position: relative;
			left: 160px;
			bottom: 30px;
			z-index: 99;
		}
	}

	.mat-radio-label-content {
		width: 185px;
		height: 100px;
		background: #FFFFFF;
		border: 1px solid #DFE3E9;
		border-radius: 12px;
		position: relative;
		cursor: pointer;
		right: 20px;
		height: 100%;
		padding: 14px;

		.card-box {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 4px;
			margin: 0;

			.title {
				font-weight: 400;
				font-size: 13px;
			}

			.desc {
				font-weight: 400;
				font-size: 11px;
			}
		}
	}
}


.radio-input {
	display: inline-block;
	margin-right: 48px;

	input[type="radio"] {
		margin-right: 8px;
	}

	.radio-label,
	.mat-radio-label-content {
		font-family: Lato;
		font-size: 14px;
		color: #0d1f2d;
		font-weight: normal;
		vertical-align: super;
	}

	.mat-radio-outer-circle {
		border-color: #7e95a3;
	}
}

.inline-radio-label {
	.mat-radio-label-content {
		display: inline-flex;
	}
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background{
	border-color: var(--bookee-primary) !important;
	background-color: var(--bookee-primary) !important;
}

.radio-group {
	.mat-radio-label-content {
		font-family: Lato;
		font-size: 14px;
		color: #0d1f2d;
		font-weight: normal;
		vertical-align: super;
	}

	.mat-radio-disabled {
		.mat-radio-label-content {
			color: rgba(0, 0, 0, 0.38);
		}
	}

	.mdc-form-field label{
		margin: 0;
	}
}


.checkbox-input {
	display: inline-block;
	margin-right: 48px;

	input[type="checkbox"] {
		margin-right: 8px;
	}

	.checkbox-label,
	.mat-checkbox-label {
		font-family: Lato;
		font-size: 14px;
		font-weight: normal;
		color: #0d1f2d;
		vertical-align: middle;
	}

	.mat-checkbox-layout {
		display: inline !important;
	}

	.mat-checkbox-frame {
		border-color: #7e95a3;
	}
}


.datepicker-container {
	position: relative;
	width: 144px;
	height: 40px;

	.date-overlay {
		position: absolute;
		display: flex;
		align-items: center;
		z-index: 1;
		height: 40px;
		width: 100%;
		border: solid 1px #dfe3e9;
		border-radius: 6px;
		padding: 12px;
		cursor: pointer;

		.custom-date {
			font-family: Lato;
			font-size: 14px;
			color: #0d1f2d;

			&.placeholder {
				color: #7e95a3;
			}
		}

		.datepicker-icon {
			display: inline-block;
			margin-left: auto;
			width: 24px;
			height: 24px;
			background: url("/assets/img/datepicker-icon.svg");
		}
	}

	.datepicker-input {
		width: 300px;
		pointer-events: all;
	}
}

.form-page {
	.mat-checkbox-label {
		font-family: Lato;
		font-size: 14px;
		font-weight: normal;
		color: #0d1f2d;
	}

	.mat-checkbox-layout {
		white-space: unset !important;
	}

	.form-fitday .form-field label {
		display: flex !important;
	}
}

.show-advanced {
	&.collapsed {
		cursor: pointer;

		&:before {
			content: "SHOW ADVANCED SETTINGS";
		}

		&:after {
			content: "";
			height: 16px;
			width: 16px;
			background-image: url("/assets/img/arrow-down.svg");
			position: absolute;
			margin-left: 3px;
		}
	}
}

.show-advanced {
	&:not(.collapsed) {
		cursor: pointer;

		&:before {
			content: "HIDE ADVANCED SETTINGS";
		}

		&:after {
			content: "";
			height: 16px;
			width: 16px;
			background-image: url("/assets/img/arrow-down.svg");
			transform: rotate(180deg);
			position: absolute;
			margin-left: 3px;
		}
	}
}

.btn-theme {
	width: 144px;
	height: 40px;
}

.btn-theme-violet {
	color: #fff !important;
	background-color: #7850FA;
	font-family: Lato;
	font-size: 12px;
	padding: 10px;
	border: solid 1px #7850FA;
	text-align: center;
}

.btn-theme-grey {
	color: grey !important;
	background-color: wgite;
	font-family: Lato;
	font-size: 12px;
	padding: 10px;
	border: solid 1px grey;
	text-align: center;
}


.fitday-timepicker {
	width: 120px;
	height: 40px;

	.p-inputtext {
		background: url("/assets/img/timepicker-icon.svg");
		background-repeat: no-repeat;
		background-position-y: center;
		background-position-x: 95%;
		border: 1px solid #DFE3E9;
		padding: 10px;
		border-radius: 6px;

		&:hover {
			border: 1px solid #7850FA !important;
		}

		&:focus {
			border: 1px solid #7850FA !important;
		}
	}

	.p-calendar.p-focus {
		box-shadow: none;
	}

	.p-datepicker-timeonly {
		width: 160px;
		border: 1px solid #dfe3e9 !important;
		box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08) !important;
		margin-top: 4px;
		padding: 0;
	}

	.pi-chevron-up:before,
	.pi-chevron-down:before {
		color: #7f97a6;
		font-size: 18px;
	}

	.p-hour-picker {
		margin-left: 0 !important;
	}

	.p-datepicker {
		.p-timepicker {
			padding: 0px;

			span {
				font-size: 14px;
				color: #7850FA !important;
			}
		}
	}
}


.bs-datepicker {
	box-shadow: 0 2px 20px -2px rgba(0, 0, 0, 0.06) !important;
	border: 1px solid #dfe3e9;
	margin-top: 3px;

	.bs-datepicker-container {
		padding: 0 !important;
	}

	.bs-datepicker-head {
		background-color: #fff !important;

		button {
			color: #354052;

			&:hover {
				background-color: #7850FA26 !important;
			}

			&.previous {
				&:disabled {
					color: #dfe3e9;
					cursor: not-allowed;
					background: unset;
				}
			}
		}
	}

	.bs-datepicker-body {
		border-top: 1px solid #dfe3e9;
		padding-top: 16px;

		table {
			th {
				text-transform: uppercase;
				padding-bottom: 8px;
			}

			td {
				color: #354052;
				padding-bottom: 5px;
			}
		}

		span {
			&.selected {
				background-color: #7850FA !important;
			}

			&:not(.selected) {
				&:hover {
					background-color: #7850FA26 !important;
				}
			}

			&.disabled {
				&:hover {
					background-color: unset !important;
					cursor: not-allowed;
				}
			}

			&.is-other-month {
				background-color: unset !important;
				cursor: not-allowed;
			}
		}
	}
}


.currency-price {
	position: relative;
	display: inline-block;
	max-width: 200px;

	.currency-prefix {
		height: 40px;
		width: 40px;
		position: absolute;
		display: flex;
		margin-top: 10px;
		padding-left: 8px;

		.currency-label {
			color: #7e95a3;
			font-family: Lato;
			font-size: 14px;
			font-weight: 500;
			margin: 0;
		}
	}

	.price-input {
		padding-left: 40px !important;
		text-align: right;
		border: 1px solid #dfe3e9;
	}
}

.currency-percentage {
	width: 104px;
	padding-right: 30px;
	display: inline;

	input {
		border: 1px solid #dfe3e9;
	}

	.currency-postfix {
		margin-left: -25px;
		color: #7e95a3;
		font-family: Lato;
		font-size: 14px;
		font-weight: 500;
	}
}


.search-input-box {
	width: 100%;
	height: 56px;
	border: 1px solid #dfe3e9;
	padding: 12px;
	padding-left: 50px;
	background-image: url("/assets/img/search-pos.svg");
	background-repeat: no-repeat;
	background-position-x: 16px;
	background-position-y: center;
}


.btn-a {
	display: inline-block;
	text-align: center;
	min-width: 144px;
	font-size: 13px;
	line-height: 18px;
	height: 40px;
	text-align: center;
}

.btn-a:hover {
	color: white;
	text-decoration: none;
	outline: none;
}

.btn-leads {
	border: 1px solid #7e95a3;
	display: inline-block;
	min-width: 144px;
	font-size: 13px;
	line-height: 18px;
	height: 40px;
	text-align: center;
}

.btn-leads:hover {
	outline: none;
}

.loader {
	position: relative;
	margin: 0 auto;
	width: 50px;
}

.loader:before {
	content: "";
	display: block;
	padding-top: 100%;
}

.circular {
	-webkit-animation: rotate 2s linear infinite;
	animation: rotate 2s linear infinite;
	height: 100%;
	-webkit-transform-origin: center center;
	transform-origin: center center;
	width: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}

.path {
	stroke-dasharray: 1, 200;
	stroke-dashoffset: 0;
	-webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
	animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
	stroke-linecap: round;
}

@-webkit-keyframes rotate {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes rotate {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-webkit-keyframes dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}

	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35px;
	}

	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124px;
	}
}

@keyframes dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}

	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35px;
	}

	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124px;
	}
}

@-webkit-keyframes color {

	100%,
	0% {
		stroke: $theme-color;
	}

	40% {
		stroke: $theme-color;
	}

	66% {
		stroke: $theme-color;
	}

	80%,
	90% {
		stroke: $theme-color;
	}
}

@keyframes color {

	100%,
	0% {
		stroke: $theme-color;
	}

	40% {
		stroke: $theme-color;
	}

	66% {
		stroke: $theme-color;
	}

	80%,
	90% {
		stroke: $theme-color;
	}
}



.showbox {
	position: relative;
	top: 60px;
	padding: 5%;
}

.fix-height {
	height: 260px;
}

.text-overflow-ellipsis {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.body-title {
	width: 200px;
	height: 16px;
	font-family: Lato;
	font-size: 12px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.33;

	color: #0d1f2d;
	margin-bottom: 38px;
	margin-top: 40px;

	span {
		display: flex;
		width: 40px;
		height: 2px;
		background-color: #0d1f2d;
		margin-top: 14px;
	}
}




.aside-profile-left {
	padding-right: 0px !important;
	max-width: 255px !important;
	font-family: Lato;

	.edit-icon {
		margin-left: 2.5px;
	}

	.profile-left {

		width: 220px;
		border-radius: 12px;
		border: solid 1px var(--light-periwinkle);
		padding: 20px 16px;
		float: right;
		margin-right: 24px;
		margin-bottom: 24px;

		.section-image {
			width: 120px;
			height: 120px;
			margin-left: auto;
			margin-right: auto;
			background-color: rgba(0, 0, 0, 0.2);
			border-radius: 50%;

			.upload-image-text {
				font-size: 12px;
				font-weight: 600;
				line-height: 1.33;

				text-align: center;
				color: #ffffff;
				position: absolute;
				margin-top: 54px;
				margin-left: 14px;
			}

			.profile-picture>img {
				height: 120px;
				width: 120px !important;
				object-fit: cover;
				border-radius: 50%;
			}

			.hover-on-pic {
				height: -120px;
				visibility: hidden;
				border-radius: 50%;
				background-color: none;

				a {
					visibility: hidden;
					height: 120px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 12px;
					font-weight: 600;
					line-height: 1.33;
					letter-spacing: 0px;
					color: #ffffff;
				}
			}

			.profile-picture:hover .hover-on-pic {
				visibility: visible;
				width: 120px;
				height: 120px;
				position: absolute;
				top: 20px;
				transition: .5s ease;
				box-shadow: 0 8px 20px -4px rgba(33, 33, 33, 0.3);
				background-color: rgba(0, 0, 0, 0.35);

				a {
					visibility: visible;
					height: 120px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 12px;
					font-weight: 600;
					line-height: 1.33;
					letter-spacing: 0px;
					color: #ffffff;
				}

			}
		}

		.section-details {
			.sec-via {
				font-size: 10px;
				font-weight: bold;
				line-height: 1.6;
				letter-spacing: 0px;
				color: #7e95a3;
				margin-top: 20px;
				display: block;
				margin-bottom: 0px;
				text-align: center;

			}

			.sec-name {
				font-size: 16px;
				font-weight: bold;
				color: #0d1f2d;


				text-align: center;


				margin-top: 16px;
				display: block;
				padding-bottom: 8px;
				margin-bottom: 0px;
			}

			.sec-status {
				font-size: 12px;
				font-weight: bold;
				font-style: normal;
				line-height: 1.33;

				margin-top: 4px;
				margin-bottom: 0px;
				display: block;
				text-align: center;

			}

			.sec-type {
				font-size: 13px;
				font-weight: normal;
				font-style: normal;
				line-height: 1.38;
				letter-spacing: normal;
				color: #546a7b;
				display: block;
				margin-top: 4px;
				margin-bottom: 0px;
				text-transform: uppercase !important;
				text-align: center;

			}

			.sec-phone {
				text-align: center;
				font-size: 12px;
				color: #0d1f2d;
				display: block;
				margin-top: 4px;
				margin-bottom: 0px;
			}

			.sec-email {
				text-align: center;
				font-size: 12px;
				color: #0d1f2d;
				display: block;
				margin-top: 4px;
				margin-bottom: 0px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}

			.sec-referred-by {
				font-weight: 400;
				font-size: 12px;
				line-height: 16px;
				text-align: center;
				color: #0D1F2D;

				span {
					font-weight: bold;
				}
			}

			.sec-stage {
				display: flex;
				justify-content: center;

				.sec-stage-btn {
					font-size: 12px;
					font-weight: 600;

					color: #7850FA;
					text-transform: capitalize;
					border: 1px solid #dfe3e9;
					height: 28px;
					width: 155px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 14px;
					cursor: pointer;
				}

				.sec-stage-popup {
					position: absolute;
					padding-top: 8px;
					padding-bottom: 8px;
					left: 110px;
					top: 110px;
					height: auto;
					width: 142px;
					box-shadow: 0 8px 24px -2px rgba(33, 33, 33, 0.16), 0 3px 8px 1px rgba(33, 33, 33, 0.1);
					background-color: #ffffff;
					animation: animo-pop-stage 0.6s ease;
					z-index: 9999999999;

					ul {
						margin-bottom: 0px;

					}

					li {
						height: 36px;
						cursor: pointer;
						display: flex;
						align-items: center;
						padding-left: 16px;
						font-size: 14px;
						line-height: 1.29;
						color: #0d1f2d;
					}

					li:hover {
						color: #7850FA;
					}
				}
			}

			.sec-assign {
				margin-top: 12px;
				height: 17px;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				margin-bottom: 4px;

				.sec-assign-label {
					font-size: 13px;
					line-height: 1.38;
					color: #546a7b;
					display: block;
					margin-top: 4px;
					margin-bottom: 0px;
				}

				.sec-assign-name {
					font-size: 12px;
					font-weight: 600;
					font-style: normal;
					font-stretch: normal;
					line-height: 2.2;

					color: #7850FA;
					margin-left: 4px;
				}
			}
		}

		.section-keys {
			display: flex;
			justify-content: center;
			padding-top: 32px;

			.btn-pos {
				width: 94px;
				height: 36px;
				border-radius: 20px;
				background-color: #7850FA;
				font-size: 12px;
				line-height: 1.33;

				text-align: center;
				color: #ffffff;
				margin-right: 10px;
				background-repeat: no-repeat;
				background-position-y: center;
				background-position-x: 20px;
				font-weight: 600;

				img {
					vertical-align: bottom !important;
				}


			}

			.btn-archive {
				width: 210px;
				height: 36px;
				border-radius: 20px;
				background-color: #7e95a3;
				font-size: 12px;
				font-weight: 600;

				color: #ffffff;
			}


			.btn-msg {
				width: 36px;
				height: 36px;
				font-size: 12px;
				border-radius: 20px;
				border: 1px solid lightgrey;
				margin-right: 10px;
				background-image: url('../img/customer-profile-msg.svg');
				background-repeat: no-repeat;
				background-position: center;
			}

			.btn-edit {
				width: 36px;
				height: 36px;
				border-radius: 20px;
				border: 1px solid lightgrey;
				background-image: url('../img/customer_profile/cp-dots.svg');
				background-repeat: no-repeat;
				background-position: center;
			}
		}

		.section-cards {
			border-top: 1px solid #eaebef;
			border-bottom: 1px solid #eaebef;
			padding-top: 16px;
			padding-bottom: 16px;
			margin-bottom: 16px;

			&#section-card-secondary {
				border-top: 0px;
				padding-top: 0px;
			}


			.sec-card-title {

				font-size: 16px;
				font-weight: bold;
				color: #0d1f2d;
				margin-bottom: 16px;
			}

			.sec-card-number {
				font-size: 14px;
				font-weight: normal;

				line-height: 1.29;

				color: #546a7b;
				margin-bottom: 16px;
			}

			.oval-button {
				width: 92px;
				height: 24px;
				border-radius: 12px;
				border: solid 1px #7850FA;
				margin-bottom: 16px;
				font-size: 11px;
				font-weight: 600;
				line-height: 1.27;
				letter-spacing: 0px;
				color: #7850FA;

			}

			.sec-card-invite {
				font-size: 14px;
				color: #0d1f2d;
				margin-bottom: 0px;
				text-transform: capitalize;

				a {
					font-size: 14px;
					color: #7850FA;
				}
			}

			.sec-card-services {
				display: flex;
				margin-bottom: 16px;

				img {
					margin-right: 8px;
				}

				p {
					font-size: 14px;
					color: #546a7b;
					margin-bottom: 0px;

					a {
						font-size: 10px;
						font-weight: 600;
						text-align: right;
						color: #7850FA;
						margin-left: 17px;
					}
				}



			}

			.staff-availibility {
				font-size: 10px;
				font-weight: 600;
				color: #7850FA;
				margin-bottom: 18px;
				margin-left: 27px;
			}
		}


	}
}

@keyframes animo-pop-stage {
	0% {
		opacity: 0;
		transform: translateX(-30px);
	}

	100% {
		opacity: 1;
		transform: translateX(0px);
	}
}

.upload-doc-options {
	.p-button {
		background: transparent;
		border: none;
		transition: none;
	}

	.p-button:enabled:hover {
		background: none;
		border: none;
	}

	.p-button:focus {
		outline: none;
		box-shadow: none;
	}

	.p-splitbutton.p-button-text.p-button-plain>.p-button:enabled:hover {
		color: #7850FA;
		background: #fff;
	}

	.p-splitbutton.p-button-text.p-button-plain>.p-button {
		color: #7850FA;
		font-size: 14px;
		font-weight: 600;
	}

	.p-tieredmenu .p-menuitem {
		color: #344054;
		font-size: 14px;
		font-weight: 500;
	}
}

.p-fileupload-buttonbar {
	display: none;
}

.p-messages {
	display: none;
}

.p-fileupload-files {
	display: none;
}

.p-fileupload .p-fileupload-content {
	padding: 0 !important;
	border: none !important;
}

.p-fileupload-content .p-progressbar {
	display: none;
}

.pos-overlay-popup {
	position: fixed;
	z-index: 999999;
	height: 100%;
	padding: 0;
	width: 100%;
	top: 0;
	left: 0;
	margin: 0;
	background-color: rgba(126, 126, 126, 0.651);
	border-radius: 3px;

}

.modal {
	z-index: 10050 !important;
}

.pop-modal-container {
	.pop-modal-overlay {
		position: fixed;
		z-index: 9999999;
		height: 100%;
		padding: 0;
		width: 100%;
		top: 0;
		left: 0;
		margin: 0;
		background-color: rgba(0, 0, 0, 0.5);
		border-radius: 3px;
	}

	#modal-overflow {
		max-height: calc(100vh - 200px);
		overflow-y: auto;
	}


	#wider-modal {
		width: 752px !important;
		height: auto !important;
		overflow-y: scroll;
		max-height: 90vh;

		.has-width {
			width: 480px;
		}

		.fix-width {
			padding-right: 24px;
		}
	}

	.pop-modal {
		padding: 24px;
		box-sizing: border-box;
		z-index: 999999999;
		background-color: #ffffff;
		width: 448px;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		animation: animo-pop-model 0.3s ease;
		transition: max-height 0.15s ease-out;
		border-radius: 10px;
		box-shadow: 0 16px 32px 0px rgba(0, 0, 0, 0.1), 0 0px 50px 0 rgba(0, 0, 0, 0.1);

		.pop-cross {
			position: absolute;
			right: 16px;
			top: 20px;
			cursor: pointer;
			width: 28px;
			height: 28px;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: all 0.4s ease-in;

			&:hover {
				background-color: #dfe3e9;
				border-radius: 50px;
				box-shadow: 0 16px 32px 0px rgba(0, 0, 0, 0.1), 0 0px 50px 0 rgba(0, 0, 0, 0.1);
			}
		}

		&.pop-no-pad {
			padding-left: 0px;
			padding-right: 0px;

			.pop-modal-header {
				padding-left: 24px;
			}
		}

		&.sm {
			padding: 16px;
			border-radius: 16px;
			border: 1px solid var(--bookee-light-gray);

			.pop-modal-header {
				padding-left: 0;

				p:not(:nth-child(1)) {
					padding: 0;
				}
			}

			mat-radio-group {
				font-size: 14px;
			}
		}

		&.pop-modal-medium {
			width: 600px !important;
			min-height: 434px;
			padding: 0px !important;

			.pop-apps-header {
				padding: 32px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-bottom: 1px solid #dfe3e9;

				.pop-apps-left {
					display: flex;
					align-items: center;

					.pop-apps-icon {
						width: 80px;
						height: 80px;
						border-radius: 8px;
						border: solid 1px #eaebef;
						display: flex;
						justify-content: space-evenly;
						align-items: center;

						img {
							align-items: center;

						}
					}

					.pop-apps-name {
						padding-left: 16px;

						.pop-apps-app-name {
							font-size: 18px;
							font-weight: bold;

							color: #0d1f2d;
							margin-bottom: 4px;
							display: block;
						}

						.pop-apps-app-subline {
							font-size: 14px;
							display: block;
							color: #546a7b;
						}

					}
				}

			}

			.pop-apps-desc {
				padding: 24px 32px 32px 32px;

				p {
					font-size: 14px;
					line-height: 1.29;
					color: #0d1f2d;
				}

				.text-desc {
					font-size: 16px;
					font-weight: bold;
					color: #0d1f2d;
					margin-bottom: 8px;
				}

				.text-support {
					font-size: 14px;
					color: #4c5b67;
				}

				.border-box {
					padding: 16px;
					border-radius: 8px;
					border: solid 1px #dfe3e9;


				}

				.btn-default {
					text-transform: unset !important;
				}

				.text-normal {
					font-size: 14px;
					color: #0d1f2d;

					span {
						font-weight: bold;
					}
				}
			}

			.pop-apps-settings {
				max-height: 44vh;
				overflow-y: auto;
				padding: 24px 32px 32px 32px;

				.set-info {
					margin-bottom: 16px;

					.set-info-head {
						font-size: 16px;
						font-weight: bold;
						color: #0d1f2d;
						margin-bottom: 8px;
					}

					.set-info-content {
						font-size: 14px;
						color: #0d1f2d;
					}
				}

				.set-container {

					.set-container-flex {
						display: flex;
						width: 100%;

						.set-container-content {
							margin-bottom: 12px;
							font-size: 12px;

							&.label-left {
								padding-bottom: 4px;
								width: 40%;
								font-weight: bold;
								color: #7e95a3;
								text-transform: uppercase;
							}

							&.label-right {
								padding-bottom: 4px;
								width: 60%;
								font-weight: bold;
								color: #7e95a3;
								text-transform: uppercase;
							}

							&.set-container-left {
								display: flex;
								align-items: center;
								width: 40%;
							}

							&.set-container-right {
								width: 60%;
							}

							& .d-btn {
								font-size: 12px;
							}

						}

					}

					.set-field-label {
						font-size: 16px;
						font-weight: bold;
						color: #0d1f2d;
						margin-bottom: 8px;
					}

					.set-field-tagname {
						font-size: 14px;
						color: #0d1f2d;
						margin-bottom: 12px;
					}

					.setting-description {
						.title {
							font-size: 16px;
							font-weight: bold;
							color: #0d1f2d;
							margin-bottom: 8px;
						}

						.description {
							font-size: 14px;
							color: #0d1f2d;
						}

						.mat-radio-label {
							margin-bottom: 0px !important;
						}
					}

				}
			}

		}


		&#upload-modal {
			width: 600px !important;
			padding: 0px !important;

			.pop-modal-header {
				padding: 16px 20px;
			}
		}

		&.bookee-update {
			padding: 16px;
			position: fixed;
			top: auto;
			left: 160px;
			bottom: -32px;
			display: flex;
			gap: 12px;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			max-width: 170px;

			.update-text-container {
				display: flex;
				flex-direction: column;

				.update-text {
					font-size: 16px;
					font-weight: bold;
				}

				.update-subtext {
					font-size: 12px;
					font-weight: normal;
				}
			}
			.update-btn {
				width: 100%;
				button {
					width: 100%;
				}
			}
		}

		.pop-lead-header {
			width: 400px;
			height: 64px;
			background-color: #f0f1f5;
			padding: 12px;
			display: flex;
			margin-bottom: 24px;

			div:nth-child(1) {
				img {
					width: 40px;
					height: 40px;
					border-radius: 50%;
				}

				margin-right:12px;
			}

			div:nth-child(2) {
				p:nth-child(1) {
					font-size: 14px;
					font-weight: 600;
					line-height: 1.29;
					color: #0d1f2d;
					margin-bottom: 2px;
					margin-top: 2.5px;
				}

				p:nth-child(2) {
					font-size: 13px;
					line-height: 1.23;
					color: #546a7b;
					margin-bottom: 1px;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
					width: 308px;
				}
			}
		}

		.pop-modal-header {
			p:nth-child(1) {
				font-size: 18px;
				font-weight: bold;
				font-style: normal;
				line-height: normal;

				color: #0d1f2d;
				margin-bottom: 0px;
			}

			.pop-modal-inner {
				padding: 8px;
				padding: 12px 16px 16px;
				background-color: #f5f6fa;
				border-radius: 8px;
			}

			p:not(:nth-child(1)) {
				font-size: 14px;
				font-weight: normal;
				font-style: normal;
				line-height: 1.43;
				letter-spacing: normal;
				color: var(--bookee-black);
				margin-top: 8px;
				margin-bottom: 0px;
				padding-right: 24px;
			}



			padding-bottom:18px;
		}

		.pop-modal-content {
			.pop-model-highlight {
				font-size: 13px;
				font-weight: normal;
				font-style: italic;
				line-height: 1.23;
				letter-spacing: normal;
				color: #7850FA;
				margin-top: 4px;
			}

			.pop-lead-arrowed {
				display: flex;

				.pop-lead-assigned {
					width: 133px;
					height: 40px;
					border: 0.5px dotted #546a7b;
					font-size: 14px;
					font-weight: bold;
					line-height: 1.29;
					color: #546a7b;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					box-sizing: content-box;
					padding: 0 3px;
				}

				.pop-lead-arrow {
					width: 44px;
					height: 40px;
					background-repeat: no-repeat;
					background-position: center;
				}

				.pop-lead-select {
					height: 40px;
				}
			}

			.form-field-inline {
				display: flex;

				.datepicker-container {
					position: relative;

					.date-overlay {
						position: absolute;
						display: flex;
						align-items: center;
						z-index: 1;
						width: 144px;
						height: 40px;
						padding: 12px;
						cursor: pointer;

						.custom-date {
							font-size: 14px;
							color: #0d1f2d;

							&.placeholder {
								color: #7e95a3;
							}
						}

						.datepicker-icon {
							display: inline-block;
							margin-left: auto;
							width: 24px;
							height: 24px;
							background: url("/assets/img/datepicker-icon.svg");
						}
					}

					.datepicker-input {
						width: 120px;
						pointer-events: all;
					}
				}
			}
		}

		.pop-modal-footer {
			padding-top: 24px;

			&.jc-flex-end {
				display: flex;
				justify-content: flex-end;

				button {
					width: auto !important
				}
			}

			.buttons {
				display: flex;

				#delete-pop-btn {
					background-color: #e65055;
					border: 0px;
					&:disabled {
						background-color: #eaebef;
						color: #546a7b;
						border-color: #EAEBEF;
					}
				}

				button {
					margin-right: 16px;
					font-size: 13px;
					width: 192px;
					height: 40px;
					border-radius: 4px;
				}
			}
		}
	}
}



.aside-profile-right {
	padding-left: 0px !important;

	.profile-right {
		min-height: calc(100vh - 136px);
		margin-bottom: 150px;

		.card {
			border: 0px;
			border-radius: 0px;
			box-shadow: 0 3px 8px -1px rgba(33, 33, 33, 0.12);
		}

		.profile-tabs {
			height: 48px;
			margin-bottom: 8px;
			padding-left: 24px;

			li {
				font-size: 14px;
				font-weight: 600;
				letter-spacing: 0px;
				color: #7e95a3;
				display: inline-block;
				margin-right: 32px;
				line-height: 20px;
				padding: 13px 1px;
				cursor: pointer;
			}

			li.selected {
				font-size: 14px;
				font-weight: bold;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.29;
				letter-spacing: 0px;
				color: #0d1f2d;
			}

			li.selected:after {
				content: "";
				display: block;
				margin: 0 auto;
				position: absolute;
				width: 40px;
				height: 2px;
				background-color: black;
				margin-top: 11px;
				margin-left: unset;
				-webkit-animation: 0.5s slider-nav;
				animation: 0.5s slider-nav;
			}

		}

		.tab-navigation {
			content: " ";
			width: 94%;
			margin-left: auto;
			margin-right: auto;
			margin-top: -13px;
			background: #dfe3e9;
			border: 1px solid #dfe3e9;
		}
	}
}

.profile-no-wrap {
	flex-wrap: nowrap !important;
	margin-left: -15px;
	margin-right: 0px !important;
}


@keyframes slider-nav {
	0% {
		transform: translateX(-20px);
	}

	100% {
		transform: translateX(0px);
	}
}


.profile-timeline {
	padding: 0px 42px;

	.btn-timeline {
		width: 216px;
		height: 44px;
		box-shadow: 0 8px 16px -2px rgba(33, 33, 33, 0.2), 0 3px 6px 1px rgba(33, 33, 33, 0.08);
		background-color: #7850FA;

		img {
			background-color: #7850FA;
			position: absolute;
			left: 55px;
			margin-top: 1px;
		}

		.icon {
			z-index: 3 !important;

		}

		span:nth-child(2) {
			font-family: Lato;
			font-size: 14px;
			font-weight: 600;
			color: #ffffff;
			margin-left: 9px;
			line-height: 2;
		}
	}

	.timeline-content {

		ul {
			margin-top: 36px;
			padding-left: 8px;

			.status-line {
				width: 100%;
				height: 100%;

				.line {
					height: 100%;
					width: 1px;
					background-color: grey;
				}
			}

			li {
				display: flex;


				.timeline-content-left {
					height: 32px;
					overflow: hidden;
					vertical-align: middle;
					margin-top: auto;
					margin-bottom: auto;
					z-index: 2;

				}

				.timeline-content-right {
					padding-left: 24px;

					p:nth-child(1) {
						margin-top: 15px;
						margin-bottom: 4px;
						font-size: 11px;
						color: #7e95a3;
					}

					p:nth-child(2) {
						margin-bottom: 4px;
						font-size: 14px;
						color: #0d1f2d;
						width: 550px;
					}

					p:nth-child(3) {
						margin-bottom: 4px;
						font-size: 11px;
						color: #7e95a3;
					}

					p:nth-child(4) {
						margin-bottom: 15px;
						font-size: 13px;
						color: #7e95a3;
						font-style: italic;
					}
				}
			}


		}
	}
}

.profile-details {
	padding: 0px 32px 24px 32px;
	position: relative;

	.profile-detail-content {
		.profile-field-inline {
			display: flex;

			.form-field {
				margin-bottom: 0px;
			}

			div:nth-child(2) {
				margin-left: 32px;
			}

			div:nth-child(3) {
				margin-left: 32px;
			}
		}

		.profile-field-value {
			font-family: Lato;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			line-height: 1.29;
			letter-spacing: normal;
			color: #0d1f2d;
			margin-bottom: 24px;
		}
	}

	.profile-detail-edit {
		margin-top: 32px;
		text-align: end;

		.profile-edit-btn {
			width: 144px;
			height: 40px;
		}
	}
}


[image-place='0'] {
	background-image: linear-gradient(144deg, #ffa7e7, #ea6362);
}

[image-place='1'] {
	background-image: linear-gradient(144deg, #17ead9, #6078ea);
}

[image-place='2'] {
	background-image: linear-gradient(144deg, #17ead9, #6078ea);
}

[image-place='3'] {
	background-image: linear-gradient(144deg, #ffdea7, #ea6362);
}

[image-place='4'] {
	background-image: linear-gradient(142deg, #9b3cb7, #ff396f);
}

[image-place='5'] {
	background-image: linear-gradient(143deg, #004b91, #78cc37);
}

[image-place='6'] {
	background-image: linear-gradient(144deg, #38b8f2, #843cf6);
}

[image-place='7'] {
	background-image: linear-gradient(144deg, #e35c67, #381ce2);
}

[image-place='8'] {
	background-image: linear-gradient(144deg, #efbfd5, #9d61fd);
}

[image-place='9'] {
	background-image: linear-gradient(144deg, #45e0a7, #d5e969);
}

.image-holder {
	width: 48px;
	height: 48px;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	font-size: 14px;
	letter-spacing: 1px;
	font-weight: 900;
}


.inner-line {
	height: 56px;
}

.inner-navigator {
	padding-left: 0px;
	display: flex;

	.inner-nav-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	p {
		margin-bottom: 0px;
	}

	font-family:lato;

	.inner-nav-top {
		display: flex;
		cursor: pointer;

		p {
			font-family: Lato;
			font-size: 14px;
			margin-left: 8px;
			color: #546a7b;
			line-height: 20px;
		}

		margin-bottom:6px;
	}

	.inner-nav-header {
		p {
			font-family: Lato;
			font-size: 22px;
			font-weight: 700;
			line-height: 1.25;
			letter-spacing: 0px;
			color: #0d1f2d;
		}
	}

	.inner-nav-right {
		margin-left: auto;
		margin-top: auto;
		margin-bottom: auto;
		display: flex;

        .p-dropdown{
            align-items: center;
            padding: 8px 12px;

            /* Primary 500 */

            background: #7850FA;
            border-radius: 8px;

            /* Inside auto layout */

            flex: none;
            order: 1;
            flex-grow: 0;
        }

        .p-dropdown .p-dropdown-label.p-placeholder {
            color: #ffffff;
        }

        .p-dropdown .p-dropdown-trigger {
            color: #ffffff;
        }

        .p-dropdown-label{
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            /* identical to box height */


            /* White */

            color: #FFFFFF;
        }

		.search {
			width: 32px;
			height: 32px;
			background-image: url("/assets/img/inner-nav-search.svg");
			margin: auto;
			margin-right: 16px;

		}

		.settings-wheel {
			width: 32px;
			height: 32px;
			background-image: url("/assets/img/settingsWheel.svg");
			margin: auto;
		}

		.settings {
			display: inline-block;
			height: 22px;
			width: 22px;
			background-image: url("/assets/img/settings.svg");
			background-position: center;
			vertical-align: middle;
			margin-right: 20px;
		}

		.create-service {
			display: inline-block;
			margin-left: 24px;


			#btn-one {
				width: 120px;
				margin-right: 16px;
			}

			button {
				font-size: 13px;
			}
		}
	}

}

.inner-tabigator {
	ul {
		margin: 0px;
		padding: 0px;
		display: flex;
		margin-top: 16px;
	}

	li {
		font-size: 14px;
		color: #546a7b;
		padding-bottom: 8px;
		margin-right: 32px;
		cursor: pointer;
		text-transform: capitalize;
	}


	.active {
		border-bottom: 2px solid #9b72ee;
		color: #7041cc !important;
	}
}

.inner-navigator-line {
	height: 2px;
	background-color: #eaebef;
	width: 100%;
	margin-top: -2px;
}


/* total width */
section.site-content::-webkit-scrollbar {
	background-color: #fff;
	width: 14px
}

/* background of the scrollbar except button or resizer */
section.site-content::-webkit-scrollbar-track {
	background-color: #fff;
	width: 15px;
}

/* scrollbar itself */
section.site-content::-webkit-scrollbar-thumb {
	background-color: #babac0;
	border-radius: 16px;
	border: 4px solid #fff
}

/* set button(top and bottom of the scrollbar) */
section.site-content::-webkit-scrollbar-button {
	display: none
}



/* total width */
.content::-webkit-scrollbar {
	background-color: #fff;
	width: 14px
}

/* background of the scrollbar except button or resizer */
.content::-webkit-scrollbar-track {
	background-color: #fff;
	width: 15px;
}

/* scrollbar itself */
.content::-webkit-scrollbar-thumb {
	background-color: #babac0;
	border-radius: 16px;
	border: 4px solid #fff
}

/* set button(top and bottom of the scrollbar) */
.content::-webkit-scrollbar-button {
	display: none
}


.lined {

	position: relative;
	margin-left: -655px;

	.outer {
		width: 1px;
		min-height: 100%;
		margin: auto;
		position: absolute;
		left: 64px;
		overflow: hidden;
	}

	.inner {
		position: absolute;
		/* width: 100%; */
		height: 100%;
		background: #dfe3e9;
		;
		/* top: 5%; */
		box-shadow: 0px 0px 30px 20px #dfe3e9;
	}
}

.datepicker-input {
	input {
		opacity: 0 !important;
	}

	bs-datepicker-container {
		left: auto !important;
		transform: translate3d(0px, 42px, 0px) !important;
	}
}

.accordion {
	.card {
		overflow: visible !important;
	}
}

.belongs-pattern-settings {
	margin-top: 16px;

	.set-flex {
		display: flex;
		padding-top: 15px;
		font-family: Lato;
		color: #0d1f2d;

		mat-radio-button {
			display: block;
			font-size: 13px;
		}

		.pause-options {
			margin-top: -6px !important;
			font-size: 14px;
			display: inline;

			#pause-option-bolder {
				font-weight: bold;
				color: #0d1f2d;
			}


			input {
				width: 45px;
				height: 40px;
				margin: 0 8px;
				padding: 6px;
				border: 0.5px solid #dfe3e9;
				border-radius: 6px;

				&:focus {
					border-color: var(--bookee-primary);
				}

				&.invalid {
					border-color: var(--bookee-red);
				}
			}

			select {
				height: 40px;
				margin: 0 8px;
				padding: 6px;
				border: 0.5px solid #dfe3e9;
				border-radius: 6px;
				cursor: pointer;

				&:focus {
					border-color: var(--bookee-primary);
				}
			}
		}
	}
}


.belongs-settings {
	margin-top: 16px;

	.card {
		width: 768px;
		background-color: #ffffff;
		border-radius: 8px;
		box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
	}

	.payment-mode {
		padding-left: 32px;
		padding-right: 32px;
		padding-bottom: 0px;
		margin-top: 16px;
		margin-bottom: 16px;

		.mat-checkbox-label {
			margin-top: -2px !important;
		}

		.payment-mode-header {
			font-family: Lato;
			font-size: 12px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.33;
			letter-spacing: 0px;
			color: #7e95a3;
			border-bottom: 1px solid #eaebef;
			padding-top: 20px;
			padding-bottom: 19px;
			margin-bottom: 24px;
		}

		.payment-mode-topic {
			font-family: Lato;
			font-size: 14px;
			line-height: 1.29;
			color: #0d1f2d;
		}

		ul:not(.p-dropdown-items) {
			margin-top: 18px;

			li {
				padding-bottom: 12px;

				mat-checkbox {
					label {
						margin-bottom: 0px !important;
					}
				}

				img {
					margin-left: 12px;
					margin-top: -4px;
				}

				span {
					margin-left: 14px;
					font-size: 14px;
					color: #0d1f2d;
					position: absolute;
					margin-top: 1px;
				}

			}

			margin-bottom:8px;
		}
	}

	.payment-type {

		.webportal-desc {
			p:nth-child(1) {
				font-size: 14px;
				line-height: 1.29;
				letter-spacing: normal;
				color: #0d1f2d;
				margin-bottom: 0px;
			}

			p:nth-child(2) {
				font-size: 14px;
				line-height: 1.29;
				letter-spacing: normal;
				color: #0d1f2d;
				margin-bottom: 0px;
				margin-top: 16px;
			}

			p:nth-child(3) {
				font-size: 16px;
				line-height: 1.25;
				color: #007ace;
				margin-top: 24px;
				margin-bottom: 0px;
			}

			button {
				margin-top: 24px;
				width: 144px;

				margin-bottom: 32px;
			}

			/* Special form fields */
			.form-field {
				.settings-label {
					font-size: 14px;
					font-weight: bold;
					margin-bottom: 4px;
					text-align: left;
					color: #0d1f2d;
				}

				.form-subtext {
					font-size: 13px;
					font-weight: normal;
					margin-top: 0;
					margin-bottom: 12px;
					color: #546a7b;
				}
			}



		}


		display: flex;

		.checkbox-container {
			margin-right: 16px;
		}

		.setting-details-container {
			p {
				margin-bottom: 8px;
				font-size: 14px;
				color: #0d1f2d;
			}

			span {
				font-size: 14px;
				color: #546a7b;
			}

			.tax-rate {
				display: flex;
				margin-top: 33px;
				height: 36px;

				p {
					font-size: 14px;
					color: #0d1f2d;
					margin-right: 16px;
					margin-top: auto;
				}

				input {
					border: 1px solid #dfe3e9;
					width: 80px;
					height: 36px;
					padding-right: 24px;

					&:focus {
						border: solid 1px #7850FA;
					}
				}

				span {
					position: absolute;
					margin-left: -20px;
					width: 12px;
					height: 18px;
					font-size: 14px;
					font-weight: 600;
					margin-top: 8px;
					color: #7e95a3;
				}
			}

			padding-bottom:32px;

		}
	}
}


.webstore-navigator {
	position: absolute;
	margin-left: 24px;
	margin-top: 16px;

	img {
		position: absolute;
		margin-top: 9px;
		margin-left: 10px;

	}

	#webstore-go-btn {
		width: 152px;
		height: 36px;
		border-radius: 2px;
		border: solid 1px #dfe3e9;
		background-color: rgba(0, 0, 0, 0.03);
		line-height: 1.1;
		padding-left: 32px;
	}
}





.sy-table {
	margin-top: 16px;
	box-shadow: 0 0 8px 0 rgba(33, 33, 33, 0.1);
	background-color: #ffffff;
	width: 100%;
	padding-left: 20px;
	padding-right: 20px;
	font-family: lato;
	border-radius: 8px;


	.table-theme {

		.table-theme-header {
			tr {
				th {
					font-size: 12px;
					font-weight: 600;

					color: #7e95a3;
					border-top: 0px;
					border-bottom: 0px;
					padding-top: 16px;
					padding-bottom: 16px;

				}
			}

		}

		.table-theme-body {
			tr {
				td {
					border-top: 0px;
					border-bottom: 1px solid rgba(33, 33, 33, 0.12);
					padding-top: 16px;
					padding-bottom: 15px;
					font-size: 14px;
					line-height: 1.29;
					color: #0d1f2d;
					vertical-align: middle;

					.sy-table-line {
						padding-top: 14px;
						color: #0d1f2d;

						span {
							color: #546a7b;
						}
					}
				}
			}
		}
	}

	.payroll-theme {
		.table-theme-header {
			background-color: #fafafc;

			tr {
				background-color: #fafafc;
				border-bottom: solid 1px #eaebef;

				th {
					font-size: 12px;
					font-weight: 600;
					color: #7e95a3;
					border-top: 0px;
					border-bottom: 0px;
					padding-top: 16px;
					padding-bottom: 16px;

					&:first-of-type {
						border-top-left-radius: 8px;
					}

					&:last-of-type {
						border-top-right-radius: 8px;
					}
				}
			}

		}

		.table-theme-body {
			tr {
				&:last-of-type {
					td {
						border-bottom: none;
					}
				}

				td {
					border-top: 0px;
					border-bottom: 1px solid rgba(33, 33, 33, 0.12);
					padding-top: 16px;
					padding-bottom: 15px;
					font-size: 14px;
					line-height: 1.29;
					color: #0d1f2d;
					vertical-align: middle;

					.sy-table-line {
						padding-top: 14px;
						color: #0d1f2d;

						span {
							color: #546a7b;
						}
					}
				}
			}
		}
	}
}


.sy-table-card {
	max-width: 100%;
	display: flex;

	.sy-t-card-image {
		display: flex;
		margin-right: 20px;

	}

	.sy-t-card-profile {
		p {
			margin: 0px;
			padding: 0px;
		}

		a {
			text-decoration: none;
		}


		#full-name {
			font-size: 14px;
			font-weight: bold;
			line-height: 1.43;
			color: #0d1f2d;
			margin-top: 3px;
			cursor: pointer;

			&.just-name {
				font-size: 16px !important;
				margin-top: 12px !important;
			}
		}


		#full-detail {
			font-size: 14px;
			line-height: 1.29;
			letter-spacing: normal;
			color: #546a7b;
			margin-top: 3px;
			width: 315px;
			overflow: hidden;
			display: inline-block;
			text-overflow: ellipsis;
			white-space: nowrap;

		}
	}

}


@keyframes bookingsAnimation {
	0% {
		transform: translateX(calc(100% - 200px));
		opacity: 0;
	}

	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}





.select-upload-mode {
	display: block !important;

	.pop-modal-container {
		.pop-modal {
			padding-top: 0px;
			padding-bottom: 0px;
			padding-left: 0px;

			.pop-modal-content {


				.upload-option {
					height: 240px;
					display: flex;
					align-items: center;

					.first {
						width: 50%;
						height: 100%;
						align-items: center;
						display: flex;
						justify-content: center;
						cursor: pointer;
						background-color: #fff;
						border-right: 1px solid #f6f5f7;


						img {
							display: block;
							margin-bottom: 20px;
							margin-left: auto;
							margin-right: auto;

						}

						span {
							font-size: 13px;
							font-weight: 600;

							text-align: center;
							color: #546a7b;
							margin-right: 2px;
						}
					}

					.first:after {
						content: "OR";
						width: 40px;
						height: 40px;
						background-color: #ffffff;
						font-size: 16px;
						font-weight: 600;
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
						position: absolute;
						left: 46%;
						z-index: 999999999;

						text-align: center;
						color: #546a7b;

					}

					.first:hover {
						opacity: 0.8;
						background-color: #d5d7da;

					}

					.second:hover {
						opacity: 0.8;
						background-color: #d5d7da;

					}

					.second {
						width: 50%;
						height: 100%;
						align-items: center;
						display: flex;
						justify-content: center;
						cursor: pointer;
						background-color: #fff;
						border-left: 1px solid #f6f5f7;


						img {
							display: block;
							margin-bottom: 20px;
							margin-left: auto;
							margin-right: auto;

						}

						span {
							font-size: 13px;
							font-weight: 600;

							text-align: center;
							color: #546a7b;
							margin-left: 5px;
						}
					}
				}

			}
		}
	}
}

#web-cam-modal {
	background-color: transparent !important;
	box-shadow: none !important;
}


.btn-cal-payment {
	img {
		margin-right: 5px;
	}

	width:100%;
	height: 56px;
	border: solid 1px #7a45e6;
	border-radius:4px;
	font-size: 14px;
	font-weight: 600;
	color: #7a45e6;
}

@keyframes swipe-right {
	0% {
		opacity: 0;
		transform: translateX(40px);
	}

	100% {
		opacity: 1;
		transform: translateX(0px);
	}

}

.order-overlay {
	position: fixed;
	z-index: 99999;
	height: 100%;
	padding: 0;
	width: 100%;
	top: 0;
	left: 0;
	margin: 0;
	background-color: rgba(0, 0, 0, 0.54);
}


.character-counter {
	display: flex;
	justify-content: flex-end;

	font-size: 12px;


	color: #546a7b;
}


.striped {
	display: block;
	font-family: Lato !important;


	form.checkout button {
		padding: 0.5rem 1rem;
		color: white;
		background: coral;
		border: none;
		border-radius: 4px;
		margin-top: 1rem;
	}

	form.checkout button:active {
		background: rgb(165, 76, 43);
	}

	.StripeElement {
		width: 100%;
		height: 44px;
		margin: 0 0 24px;
		background-color: white;
		padding: 9px 12px;
		border: 1px solid #dfe3e9;
		-webkit-transition: box-shadow 150ms ease;
		transition: box-shadow 150ms ease;
		font-family: Lato;
	}

	.ElementsApp input {
		font-family: Lato;
	}

	.StripeElement--focus {
		border-color: #7850FA;
	}

	.StripeElement--invalid {
		border-color: #fa755a;
	}

	.StripeElement--webkit-autofill {
		background-color: #fefde5 !important;
	}

	#card-errors {
		font-family: Lato;
		font-size: 12px;
		color: red;
		margin-top: -18px;
	}





}


.contact-no-image {
	min-width: 44px;
	max-width: 44px;
	height: 44px;
	border-radius: 50px;
	margin-top: 1px;

	p {
		margin: 0px;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 38px;
		width: 100%;
		font-size: 13px;
		font-weight: 900;
		letter-spacing: 1px;

	}

	img {
		height: 100%;
		width: 100%;
		border-radius: 50%;
		object-fit: cover;
	}

	&.big-image {
		max-width: 72px;
	}


	&[image-border] {
		border: solid 3px #fac04b;


		p {
			color: #fac04b;

		}

	}


}




.delete-tooltip {
	width: 252px;
	height: 124px;
	border-radius: 2px;
	box-shadow: 0 8px 24px -2px rgba(33, 33, 33, 0.16), 0 3px 8px 1px rgba(33, 33, 33, 0.1);
	border: solid 1px #dfe3e9;
	background-color: #ffffff;
	position: absolute;
	left: 100px;
	z-index: 9999;
	animation: 0.3s open-tool ease;


	.tooltip-note {

		padding: 16px 16px 24px 16px;
		margin: 0px;

		p {
			font-family: Lato;
			font-size: 14px;

			line-height: 1.29;
			text-align: center;
			color: #0d1f2d;
			margin: 0px;

		}
	}

	.tooltip-footer {
		display: flex;
		justify-content: space-between;
		padding-left: 16px;
		padding-right: 16px;
		padding-bottom: 16px;

		button {
			min-width: 104px;
			height: 32px;
			border-radius: 1px;
			font-size: 10px;
			line-height: 1.1;
		}


		#dlt-btn {
			border-color: #e55055;
			background-color: #e55055;
			&:disabled {
				opacity: 0.5;
				background-color: #eaebef;
				color: #546a7b;
				border-color: #EAEBEF;
			}
		}
	}
}

@keyframes open-tool {
	0% {
		transform: scale3d(.2, .2, 1);
		transition: all .2s ease-in-out;
		opacity: 0.3;
	}

	100% {
		opacity: 1;
		transform: scale3d(1, 1, 1);
	}
}


.universal-label-green {


	border-radius: 2px;
	background-color: #37b877;
	margin-left: 8px;
	padding: 4px 8px;
	font-size: 10px;
	font-weight: 600;

	color: #ffffff;
}

#b-bottom {
	border-bottom: 1px solid #dfe3e9;
}

#b-top {
	border-top: 1px solid #dfe3e9;
}

.pad-top {
	padding-top: 24px;
}


.pad-bottom {
	padding-bottom: 24px;
}


.pad-left {
	padding-left: 24px;
}

.pad-right {
	padding-right: 24px;
}


.d-btn {
	border-radius: 6px;
	background-color: #eaebef;
	font-size: 14px;
	font-weight: bold;
	color: #0d1f2d;
	padding: 9px 16px;

	&:hover {
		background-color: darken(#eaebef, 5%);
	}

	&:disabled {
		opacity: 0.5;
		border-radius: 6px;
		background-color: #eaebef;
		color: #546a7b;
	}

	&.light {
		color: #546a7b;
	}

	&#d-btn-small {
		padding: 7px 14px;
	}

	&#d-btn-green {
		background-color: #37b877;
		color: #ffffff;
	}
}


.existing-container {
	width: 480px;
	margin-bottom: 16px;

	.existing-msg {
		font-family: Lato;
		font-size: 14px;
		color: #0d1f2d;
		margin-bottom: 16px;
	}

	.existing-detail {
		border: 1px solid #dfe3e9;
		padding: 16px;
		display: flex;


		.e-detail-initial {
			width: 64px;
			height: 64px;
			border-radius: 50%;
			background-color: #dfe3e9;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 16px;

			#initial {
				font-size: 24px;
				font-weight: bold;
				color: #ffffff;
			}
		}

		.e-detail-detail {

			.e-d-detail-name {
				font-size: 14px;
				font-weight: bold;
				color: #546a7b;

				margin-bottom: 4px;
				margin-right: 16px;

				&#e-d-detail-color-remove {
					color: #7a45e6;
					cursor: pointer;
				}
			}



			.e-d-detail-email {
				font-size: 14px;

				color: #546a7b;
				margin-bottom: 4px;
			}

			.e-d-detail-tag {
				font-size: 11px;
				font-weight: bold;


				color: #7e95a3;
			}

		}

		.e-detail-btn {
			display: flex;
			margin-left: auto;

			button {
				margin-top: auto;
				margin-bottom: auto;
			}
		}
	}
}


.dis-able .form-field input[type="text"]:disabled,
.dis-able .form-field textarea:disabled {
	background-color: #ffffff !important;
}

.dis-able select:disabled {
	background-color: #ffffff !important;
}

.opaq {
	opacity: 0.4;
	pointer-events: none;
}


.jp-text {
	padding-left: 8px;
}

#table-loader:before,
#table-loader:after {
	height: 32px;
	width: 32px;
}

#table-loader:before {
	margin-top: -16px;
	margin-left: -16px;
	border-top-color: #7a45e6;

}

.p-tool-tip-left {
	margin-left: 8px;
}

.p-tool-tip-right {
	margin-right: 8px;
}


.pill {
	font-size: 11px;
	border-radius: 11px;
	font-weight: bold;
	padding: 4px 10px;
	margin-right: 8px;

	&.squared {
		border-radius: 4px;
		padding: 6px 8px;
		font-size: 12px;
	}

	&.active {
		background-color: #f1ecfc;
		color: #7a45e6;
	}

	&.inactive {
		background-color: #f0f2fa;
		color: #7e95a3;
	}

	&.paused {
		background-color: #fcf2d9;
		color: #dc8b00;
	}

	&.success {
		background-color: rgba(61, 191, 126, 0.1);
		color: #3dbf7e;
	}

	&.danger {
		background-color: rgba(245, 100, 105, 0.1);
		color: #f56469;
	}

	&.upcoming {
		background-color: rgba(0, 172, 224, 0.1);
		color: #00ace0;
	}

	&.billing-issue {
		background-color: #e6505524;
		color: #E65055;
	}

}




.mat-radio-container {
	height: 18px;
	width: 18px;
}

.mat-radio-outer-circle {
	border-color: #7e95a3;
}

.mat-radio-outer-circle {
	height: 18px !important;
	width: 18px !important;
}

.mat-radio-inner-circle {
	height: 18px !important;
	width: 18px !important;
}




.mat-radio-button.mat-accent .mat-radio-inner-circle {
	background-color: #7850FA !important;
}

mat-checkbox {
	&.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background {
		background: #7850FA !important;
	}
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
	background: #7850FA !important;

}


.p-tooltip .p-tooltip-text {
	padding: 12px;
	border-radius: 6px;
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
	font-size: 13px;
}


#cal-loader:before,
#cal-loader:after {
	height: 32px;
	width: 32px;
}

#cal-loader:before {
	margin-top: -16px;
	margin-left: -16px;
	border-top-color: #7a45e6;
}

.tag-initial {
	width: 32px;
	height: 32px;
	border: solid 1px #eaebef;
	display: inline-flex;
	font-size: 16px;
	font-weight: bold;
	text-align: center;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	margin-right: 8px;
}

.tag-operator-table {
	font-size: 16px;
	min-width: 32px;
	height: 32px;
	text-align: center;
	border: 1px solid #dfe3e9;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	/*    margin-top:8px;
    margin-right:8px; */
}

.tag-operator-pos {
	font-size: 9px;
	min-width: 19px;
	height: 19px;
	display: inline-block;
	text-align: center;
	border: 1px solid #dfe3e9;
	border-radius: 50%;
	padding: 3px;
	margin-right: 4px !important;
}

.tag-operator-calendar {
	font-size: 10px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #dfe3e9;
	border-radius: 50%;
	margin-right: 4px !important;
	min-width: 23px;
	height: 23px;
}



.group-mode {
	display: flex;

	.disable {
		opacity: 0.7;
		background-color: #dfe3e9;
	}


	.group-type {
		width: 120px;
		height: 40px;
		font-family: Lato;
		font-size: 13px;
		font-weight: 600;
		line-height: 1.23;

		color: #7e95a3;
		border: 1px solid #dfe3e9;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		img {
			margin-right: 4px;
		}
	}


	.group-type:nth-child(2) {
		border-left: 0px;
		border-right: 0px;

	}

	.group-type:nth-child(3) {
		border-left: 0px;
	}

	.active {
		border: 1px solid #7850FA !important;
		background-color: rgba(155, 114, 238, 0.1);
		color: #7850FA;
	}
}

#jc-flex-end {
	display: flex;
	justify-content: flex-end;
}

#text-btn {
	font-size: 12px;
	cursor: pointer;
	color: #7850FA;
	margin-left: 12px;
}




.flex-centered {
	display: flex;
	align-items: center;
	justify-content: center;

}

.flex-centered-margin {
	display: flex;
	margin-left: auto;
	margin-right: auto;
	align-items: center;
	justify-content: center;
}

.text-align-center {
	text-align: center;
}

.text-align-end {
	text-align: end;
}

.text-gray-14 {
	color: var(--bookee-gray);
	font-size: 14px;
	margin-bottom: 0;
}

.text-primary-14 {
	color: var(--bookee-primary);
	font-size: 14px;
	margin-bottom: 0;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.flex-row {
	display: flex;
	flex-direction: row;
}

.jsb {
	justify-content: space-between;
}

.jac {
	justify-content: center;
	align-items: center;
}

.disp-flex {
	display: flex;
}


.font-default {
	font-size: 14px;
	color: #0d1f2d;
}

.jcfs {
	justify-content: flex-start
}


.edit-options-mem {
	display: inline-block;
	height: 200px;
	width: 100px;
	position: absolute !important;
	margin-top: 90px;
	margin-left: 800px;
	padding-left: 0px;
	top: 80px;

	button {
		width: 200px;
		height: 42px;
		font-family: Lato;
		font-size: 14px;
		font-weight: bold;
		padding: 10px;

		&:hover {
			background-color: #dfe3e9;
		}

		text-align: left;

		&:before {
			content: '';
			display: inline-block;
			width: 18px;
			height: 18px;
			background-image: url("../../assets/img/edit-copy-21.svg");
			margin-right: 10px;
			vertical-align: text-bottom;
		}

		&.save {
			color: #37b877;

			.fa {
				display: inline-block;
				margin-right: 12px;
				margin-left: 2px;
			}

			&:before {
				background-image: url("../../assets/img/edit-copy-23.svg");
			}

			&.loading::before {
				background-image: none;
				display: none;
			}
		}

		&.cancel {
			color: #546a7b;

			&:before {
				background-image: url("../../assets/img/edit-copy-22.svg");
			}

		}

		&.duplicate {
			color: #546a7b;

			&:before {
				background-image: url("../../assets/img/copy.svg");
			}

			&:disabled {
				color: #c8c8c8;
				cursor: not-allowed;
			}
		}

		&.delete {
			color: #e65055;

			&:before {
				background-image: url("../../assets/img/edit-copy-21.svg");
			}
		}
	}
}


.sm-pill {
	padding: 4px 10px;
	border-radius: 12px;
	background-color: #f0f1f5;
	font-size: 11px;
	font-weight: bold;
	color: #7e95a3;
	display: flex;
	align-items: center;
	/* height: 22px;
	width: 75px; */
}

.no-data-text {
	color: var(--bookee-gray);
	font-size: 14px;
}


.table-no-bg {

	.table-header {
		th {
			text-transform: uppercase;
			padding-bottom: 8px;
			color: #7e95a3;
			font-size: 12px;
			font-weight: bold;
		}

		td {
			color: #0d1f2d;
			font-size: 15px;
			padding-bottom: 5px;
		}
	}

	.table-body {

		.card-row {
			padding: 18px 0px;
			border-radius: 8px;
			background-color: #fff;
			margin-bottom: 16px;
		}
	}
}







// ================

.dndflow {
	flex-direction: row;
	display: flex;
	flex-grow: 1;
	height: 100%;
	background-color: #f0f0f0;
	position: relative;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
}

.dndflow {

	aside {
		backdrop-filter: blur(6px);
		border-right: 1px solid #eee;
		padding: 0;
		font-size: 12px;
		background: #fcfcfc;
		max-width: 200px;
		z-index: 1000;
		overflow-y: auto;
		height: calc(100vh - 110px);
		background: rgba(255, 255, 255, 0.75);
		box-shadow: 0 4px 12px rgb(0 0 0 / 4%);

		.header {
			color:  #7E95A3;
			font-size: 13px;
			font-weight: 700;
			margin-bottom: 8px;
		}
	}
}

.dndflow aside .description {
	margin-bottom: 10px;
}

.dndflow .dndnode {
	height: 20px;
	padding: 4px;
	border: 1px solid #1a192b;
	border-radius: 2px;
	margin-bottom: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: grab;
}

.dndflow .dndnode.input {
	border-color: #0041d0;
}

.dndflow .dndnode.output {
	border-color: #ff0072;
}

.dndflow .reactflow-wrapper {
	flex-grow: 1;
	height: 100%;
	background-color: #e8e8e8;
	position: relative;


	.beautify-canvas {
		padding: 8px;
		background-color: white;
		font-size: 12px;
		position: absolute;
		left: 0;
		z-index: 99;
	}
}

.dndflow .selectall {
	margin-top: 10px;
}



.p-password-input {

	box-shadow: none;
	width: 100%;
	font-size: 14px !important;
	background-color: #ffffff !important;
	border: solid 1px #dfe3e9 !important;
	border-radius: 6px !important;
	margin-top: 8px !important;

	&:focus {
		border: solid 1px #7850FA !important;
	}
}

.p-input-icon-right>i {

	margin-top: -3px !important;
}

.btn-minor {
	font-size: 13px !important;
	font-weight: 700 !important;
	color: #495057 !important;
	background-color: transparent !important;
	border: none !important;
	padding: 10px 16px !important;
	margin: 0 !important;
	cursor: pointer !important;
	border: 1px solid #CED4DA !important;
	border-radius: 6px;

	&:disabled {
		background-color: #e9edf1 !important;
		border: 1px solid #dfe3e9 !important;
		color: #7e95a3 !important;
		cursor: not-allowed !important;
		pointer-events: none !important;
	}
}

.btn-minor_bg {
	font-size: 13px !important;
	font-weight: 700 !important;
	color: #495057 !important;
	background-color: #EAEBEF !important;
	border: none !important;
	padding: 10px 16px !important;
	margin: 0 !important;
	cursor: pointer !important;
	border: none !important;
	border-radius: 6px;
}

.btn-text_primary {
	color: #7850FA !important;
	background-color: transparent !important;
	font-weight: 700;
	font-size: 14px;
}


.mobile_onboarding {
	background-color: white;

	.mySwiper {
		display: flex !important;
	}

	.swiper-wrapper {
		display: inline-flex !important;
	}

	.swiper-slide {
		height: 100vh !important;
		display: flex;
		overflow-y: auto;
	}


	.swipe-container {

		max-height: 570px;
		max-width: 340px;

		@media only screen and (max-width: 430px) {
			& {
				max-width: unset;
			}
		}

		margin: auto;

		.header {
			margin: 0;
			font-weight: 700;
			font-size: 20px;
			text-align: center;
			color: #101828;
		}

		.desc {
			font-size: 14px;
			text-align: center;
			color: #475467;
		}

		.header-sec {
			font-weight: 700;
			font-size: 18px;
			text-align: center;
			color: #101828;
		}

		.btn-ob {
			background: #7A45E5;
			/* Shadow/xs */

			box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
			border-radius: 4px;
			padding: 10px;
			color: #ffffff;
			font-weight: 600;
			font-size: 16px;
		}

		img {
			min-height: 186px;
		}
	}
}

.roaming-tags {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 12px;
    gap: 4px;
    border-radius: 14px;
    font-size: 11px;
	background-color: #f0f1f5;
    font-weight: bold;
    color: #7e95a3;
	img{
		height: 12px;
	}
}

.custom-slider {

	.p-slider.p-slider-horizontal {
		height: 6px;
		border-radius: 4px;
	}
	.p-slider {
		.p-slider-handle  {
			&:hover {
				border-color: var(--bookee-primary) !important;
			}
			&:focus {
				box-shadow: none;
			}
		}

		.p-slider-range {
			background: var(--bookee-primary);
			border-radius: 4px;
		}
	}
}



.rule-engine {
	.select-dropdown {
		.p-dropdown {
			border-radius: 8px;
			border: 1px solid #EAEBEF;

			&:hover {
				border-radius: 8px;
				border: 1px solid #EAEBEF !important;
				background: #E7DFFF;
			}

			&:not(.p-disabled).p-focus {
				border-radius: 8px;
				border: 1px solid #EAEBEF !important;
			}

			.p-dropdown-label {
				font-size: 12px !important;
				font-weight: bold;
				padding: 8px 4px 8px 8px;	
			}

			.p-dropdown-trigger {
				width: 28px;
			}
		}
	}

	.hovered-dropdown {
		.p-dropdown {
			background: #E7DFFF;
			border: 1px solid #EAEBEF !important;
		}
	}
}


.mat-custom-checkbox {
	.mat-checkbox-frame {
		border: 2px solid #CED4DA;
		border-radius: 6px;
	}

	.mat-checkbox-inner-container {
		width: 21px !important;
		height: 21px !important;		
	}

	.mat-checkbox-layout {
		margin: 0;
	}
}






.wf-border-btn {
	border-radius: 8px !important;
	border: 1px solid #D0D5DD;
	background: #FFF;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	padding: 10px 18px;
	height: 44px !important;
	width: 100% !important;
	color: black;
	font-size: 16px !important;
}

.wf-danger-btn {
	border-radius: 8px !important;
	border: 1px solid var(--error-600, #D92D20);
	background: var(--error-600, #D92D20);
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	padding: 10px 18px;
	height: 44px !important;
	font-size: 16px !important;
	width: 100% !important;
	margin: 0 !important;
	color: white;
}

.wf-primary-btn {
	border-radius: 8px !important;
	border: 1px solid #7850FA;
	background: #7850FA;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	padding: 10px 18px;
	height: 44px !important;
	font-size: 16px !important;
	width: 100% !important;
	margin: 0 !important;
	color: white;
}

.wf-pop-delete-text {
	color: #667085 !important;
	font-size: 14px;
	padding: 0 !important;
	margin-top: 8px;

	span {
		color: #D92D20;
	}
}
.confirm-popup-wrap {
	z-index: 9999999;
    background-color: #ffffff;
    width: 560px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: animo-pop-model 0.3s ease;
    transition: max-height 0.15s ease-out;
    border-radius: 10px;
    box-shadow: 0 16px 32px 0px rgba(0, 0, 0, 0.1), 0 0px 50px 0 rgba(0, 0, 0, 0.1);

	.confirm-pop-head {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #EAEBEF;
		padding: 16px 24px;

		p {
			font-weight: 700;
			margin: 0;
		}
	}

	.confirm-pop-body {
		padding: 16px 24px;

		.secondary-txt {
			font-size: 14px;
			color: #667085;
			font-weight: 400;
			margin: 0;
		}
	}

	.confirm-pop-footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 16px 24px;
		border-top: 1px solid #EAEBEF;
	}
}



.page-heading {
	h3 {
		font-size: 22px;
		font-weight: bold;
		color: #bcc8d1;
		margin: 0px;
		padding: 0;
		text-transform: capitalize;
	}
}

.p-toast {
	.p-toast-message {
		max-width: 350px;
	}
	.p-toast-message.p-toast-message-info {
		background: #e9e9ff !important;
		border-left: solid #696cff !important;
		color: #696cff !important;
		.p-toast-message-icon {
			color: #696cff !important;
			font-size: 18px !important;
		}
	}

	.p-toast-message.p-toast-message-success {
		background: #e4f8f0 !important;
		border-left: solid #1ea97c !important;
		color: #1ea97c !important;
		.p-toast-message-icon {
			color: #1ea97c !important;
			font-size: 18px !important;
		}
	}

	.p-toast-message.p-toast-message-warn {
		background: #fff2e2 !important;
		border-left: solid #cc8925 !important;
		color: #cc8925 !important;
		.p-toast-message-icon {
			color: #cc8925 !important;
			font-size: 18px !important;
		}
	}

	.p-toast-message.p-toast-message-error {
		background: #ffe7e6 !important;
		border-left: solid #ff5757 !important;
		color: #ff5757 !important;
		.p-toast-message-icon {
			color: #ff5757 !important;
			font-size: 18px !important;
		}
	}

	.p-toast-summary {
		text-transform: capitalize;
		font-size: 14px;
	}

	.p-toast-message-content {
		align-items: center !important;
		.p-icon:not(.p-toast-icon-close-icon) {
			height: 18px !important;
		}

		.p-toast-message-text {
			max-width: 260px !important;
		}
	}
}

.log-common-table {
	margin-top: 30px;
    margin-left: 0px !important;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.10);

	.table-header {
		color: #0D1F2D;
		font-size: 12px;
		font-weight: bold;
		line-height: 1.33;
		text-transform: uppercase;
		border-bottom: 1px solid #EAEBEF;
		background: #F9FAFB;
		padding: 16px 20px;
	}
}

.p-inputtext {
	border-color: #CED4DA !important;
	border-radius: 6px !important;
	&:focus {
		border: solid 1px #7850FA !important;
		box-shadow: none !important;
	}
}

.zero-state-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    background: #FFF;
	border-radius: 12px;
	min-height: 30em;

    .zero-text-wrap {
        display: flex;
        flex-direction: column;
        gap: 4px;
        justify-content: center;
        align-items: center;

        .primary-txt {
            color: #101828;
            font-size: 16px;
            font-weight: 600;
            margin: 0;
        }

        span {
            color: #475467;
            font-size: 14px;
            font-weight: 400;
        }
    }
}